import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import _ from "lodash";
import { SnackbarProvider } from "notistack";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Account from "./components/Account";
import Accounts from "./components/Accounts";
import AccountStates from "./components/AccountStates";
import AccountTransaction from "./components/AccountTransaction";
import AccountTransactions from "./components/AccountTransactions";
import AccountType from "./components/AccountType";
import AccountTypes from "./components/AccountTypes";
import AddAccount from "./components/AddAccount";
import AddProfile from "./components/AddProfile";
import AddTransactionType from "./components/AddTransactionType";
import AddUser from "./components/AddUser";
import Apps from "./components/Apps";
import Billers from "./components/Billers";
import BillerTransaction from "./components/BillerTransaction";
import BillerTransactions from "./components/BillerTransactions";
import CashOutOptions from "./components/CashOutOptions";
import Client from "./components/Client";
import Clients from "./components/Clients";
import Dashboard from "./components/Dashboard";
import Deposit from "./components/Deposit";
import EditBiller from "./components/EditBiller";
import EditModule from "./components/EditModule";
import EditProfile from "./components/EditProfile";
import EditTransactionType from "./components/EditTransactionType";
import EditUser_ from "./components/EditUser_";
import Homepage from "./components/Homepage";
import Login from "./components/Login";
import Module from "./components/Module";
import Modules from "./components/Modules";
import NotificationChannels from "./components/NotificationChannels";
import Notifications from "./components/Notifications";
import NotificationTemplates from "./components/NotificationTemplates";
import PersistLogin from "./components/PersistLogin";
import PinStatus from "./components/PinStatus";
import PinStatuses from "./components/PinStatuses";
import Profile from "./components/Profile";
import Profiles from "./components/Profiles";
import Requests from "./components/Requests";
import RequireAuth from "./components/RequireAuth";
import ResetPassword from "./components/ResetPassword";
import ResetPasswordLink from "./components/ResetPasswordLink";
import Roles from "./components/Roles";
import TransactionReversals from "./components/TransactionReversals";
import TransactionType from "./components/TransactionType";
import TransactionTypes from "./components/TransactionTypes";
import TransferTransaction from "./components/TransferTransaction";
import TransferTransactions from "./components/TransferTransactions";
import User from "./components/User";
import Users from "./components/Users";
import VerifyEmail from "./components/VerifyEmail";
import SelectMultiple from "./container/SelectMultiple";
import AccessDenied from "./components/AccessDenied";
import { AppContext } from "./helpers/contexts/AppContext";
import useAxiosPrivate from "./hooks/useAxiosPrivate";
import { enqueueSnackbar } from "notistack";
import EditRole from "./components/EditRole";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { Tooltip } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import { items } from "./components/listItems";

const App = () => {
  const [open, setOpen] = React.useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [isLoggedIn, setIsLoggedIn] = React.useState(true);
  const [formDetails, setFormDetails] = React.useState([]);
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertData, setAlertData] = React.useState({});
  const [exportFileName, setExportFileName] = React.useState("");
  const [auth, setAuth] = React.useState({});
  const [persist, setPersist] = React.useState(true);
  const axios = useAxiosPrivate();
  const deleteAndRedirect = (
    param,
    url,
    entity,
    redirect,
    navigate,
    redirectUrl
  ) => {
    axios.delete(`${url}/${param}`).then((res) => {
      if (res.status === 200) {
        console.log(`${entity} deleted successfully.`);
      }
    });

    redirect && navigate(redirectUrl);
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  };

  const CustomExportToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExport csvOptions={{ fileName: exportFileName }} />
      </GridToolbarContainer>
    );
  };
  const mainList = (
    <React.Fragment>
      {items.map((item, index) =>
        !_.isEmpty(auth) &&
        (auth.permissions.includes(item.role) ||
          auth.permissions.includes("ROOT")) ? (
          <Tooltip title={item.name} placement="right">
            <ListItemButton key={index}>
              <Link to={`/${item.uri}`} style={{ textDecoration: "none" }}>
                <ListItemIcon>{item.icon}</ListItemIcon>
              </Link>
              <Link to={`/${item.uri}`} style={{ textDecoration: "none" }}>
                <ListItemText
                  primary={
                    item.name.length < 15
                      ? item.name
                      : item.name.slice(0, 12).concat("...")
                  }
                  sx={{ fontSize: "12px" }}
                />
              </Link>
              <ChevronRightIcon />
            </ListItemButton>
          </Tooltip>
        ) : (
          ""
        )
      )}
    </React.Fragment>
  );

  const clearSD = () => {
    sessionStorage.clear();
  };

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const toggleBoolField = (params, fieldName) => {
    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

    // Get current value
    return thisRow[fieldName] !== undefined && thisRow[fieldName];
  };

  const viewFromTable = (e, params, navigate, viewUrl) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
    return navigate(`/${viewUrl}/${thisRow.id}`);
  };

  const handleCloseConfirmDialog = (actionPresent, handler, payload) => {
    actionPresent && typeof handler === "function" && handler(payload);
    setOpenConfirmDialog(false);
  };

  const handleOpenEditDialog = () => {
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = (
    actionPresent,
    handler,
    param,
    payload,
    url,
    endpoint,
    collection,
    entity,
    setter
  ) => {
    actionPresent &&
      typeof handler === "function" &&
      handler(param, payload, url, endpoint, collection, entity, setter);
    setOpenEditDialog(false);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const edit = (id, requestBody, url, endpoint, collection, entity, setter) => {
    let updatedRequestBody = {};
    Object.keys(requestBody).map((key) => {
      if (key !== "action" || key !== "id") {
        updatedRequestBody[_.snakeCase(key)] = requestBody[key];
      }
    });
    axios.patch(`${url}/${endpoint}/${id}`, updatedRequestBody).then((res) => {
      let updatedRow = collection.filter((row) => row.id === id)[0];
      Object.keys(requestBody).map((key) => {
        updatedRow[key] = requestBody[key];
      });
      let updatedCollection = collection.map((row) => {
        if (row.id === id) {
          return updatedRow;
        }
        return row;
      });

      if (res.status && res.status === 200) {
        enqueueSnackbar(`${entity} updated successfully.`, {
          variant: "success",
        });
        return setter(updatedCollection);
      }
      return enqueueSnackbar(`error updating ${entity}`, {
        variant: "error",
      });
    });
  };

  return (
    <SnackbarProvider autoHideDuration={3000} preventDuplicate>
      <AppContext.Provider
        value={{
          open,
          openConfirmDialog,
          toggleDrawer,
          isLoggedIn,
          setIsLoggedIn,
          formDetails,
          setFormDetails,
          handleOpenConfirmDialog,
          handleCloseConfirmDialog,
          openEditDialog,
          setOpenEditDialog,
          handleOpenEditDialog,
          handleCloseEditDialog,
          CustomToolbar,
          CustomExportToolbar,
          toggleBoolField,
          deleteAndRedirect,
          viewFromTable,
          edit,
          showAlert,
          setShowAlert,
          alertData,
          setAlertData,
          exportFileName,
          setExportFileName,
          auth,
          setAuth,
          persist,
          clearSD,
          mainList,
        }}
      >
        <BrowserRouter>
          <Routes>
            {/* Protected */}
            <Route element={<PersistLogin />}>
              <Route
                element={
                  <RequireAuth allowedPermissions={["ROOT", "CAN_LOGIN"]} />
                }
              >
                <Route
                  exact
                  path="/home"
                  element={<Dashboard children={<Homepage />} />}
                />
              </Route>

              <Route
                element={
                  <RequireAuth allowedPermissions={["ROOT", "CAN_LOGIN"]} />
                }
              >
                <Route
                  exact
                  path="/clients"
                  element={<Dashboard children={<Clients />} />}
                />
              </Route>
              <Route
                element={
                  <RequireAuth allowedPermissions={["ROOT", "CAN_LOGIN"]} />
                }
              >
                <Route
                  exact
                  path="/clients/:id"
                  element={<Dashboard children={<Client />} />}
                />
              </Route>
              <Route
                element={
                  <RequireAuth allowedPermissions={["ROOT", "CAN_LOGIN"]} />
                }
              >
                <Route
                  exact
                  path="/users"
                  element={<Dashboard children={<Users />} />}
                />
              </Route>
              <Route
                element={
                  <RequireAuth allowedPermissions={["ROOT", "CAN_LOGIN"]} />
                }
              >
                <Route
                  exact
                  path="users/add"
                  element={<Dashboard children={<AddUser />} />}
                />
              </Route>
              <Route
                element={
                  <RequireAuth allowedPermissions={["ROOT", "CAN_LOGIN"]} />
                }
              >
                <Route
                  exact
                  path="users/:id"
                  element={<Dashboard children={<User />} />}
                />
              </Route>

              <Route
                element={
                  <RequireAuth allowedPermissions={["ROOT", "CAN_LOGIN"]} />
                }
              >
                <Route
                  exact
                  path="users/:id/edit"
                  element={<Dashboard children={<EditUser_ />} />}
                />
              </Route>
              <Route
                element={
                  <RequireAuth allowedPermissions={["ROOT", "CAN_LOGIN"]} />
                }
              >
                <Route
                  exact
                  path="modules"
                  element={<Dashboard children={<Modules />} />}
                />
                <Route
                  exact
                  path="modules/:id"
                  element={<Dashboard children={<Module />} />}
                />
                <Route
                  exact
                  path="modules/:id/edit"
                  element={<Dashboard children={<EditModule />} />}
                />
              </Route>
              <Route
                element={
                  <RequireAuth allowedPermissions={["ROOT", "CAN_LOGIN"]} />
                }
              >
                <Route
                  exact
                  path="accounts"
                  element={<Dashboard children={<Accounts />} />}
                />
                <Route
                  exact
                  path="account-types"
                  element={<Dashboard children={<AccountTypes />} />}
                />
                <Route
                  exact
                  path="account-states"
                  element={<Dashboard children={<AccountStates />} />}
                />
                <Route
                  exact
                  path="pinstatuses"
                  element={<Dashboard children={<PinStatuses />} />}
                />
                <Route
                  exact
                  path="pinstatuses/:id"
                  element={<Dashboard children={<PinStatus />} />}
                />
                <Route
                  exact
                  path="roles"
                  element={<Dashboard children={<Roles />} />}
                />

                <Route
                  exact
                  path="roles/:id/edit"
                  element={<Dashboard children={<EditRole />} />}
                />

                <Route
                  exact
                  path="notifications"
                  element={<Dashboard children={<Notifications />} />}
                />
                <Route
                  exact
                  path="notification-templates"
                  element={<Dashboard children={<NotificationTemplates />} />}
                />
                <Route
                  exact
                  path="notification-channels"
                  element={<Dashboard children={<NotificationChannels />} />}
                />
                <Route
                  exact
                  path="transaction-types"
                  element={<Dashboard children={<TransactionTypes />} />}
                />
                <Route
                  exact
                  path="transaction-types/:id"
                  element={<Dashboard children={<TransactionType />} />}
                />
                <Route
                  exact
                  path="transaction-types/add"
                  element={<Dashboard children={<AddTransactionType />} />}
                />
                <Route
                  exact
                  path="transaction-types/:id/edit"
                  element={<Dashboard children={<EditTransactionType />} />}
                />
                <Route
                  exact
                  path="cashout-options"
                  element={<Dashboard children={<CashOutOptions />} />}
                />
                <Route
                  exact
                  path="billers"
                  element={<Dashboard children={<Billers />} />}
                />
                <Route
                  exact
                  path="billers/:id/edit"
                  element={<Dashboard children={<EditBiller />} />}
                />
                <Route
                  exact
                  path="accounts/:id"
                  element={<Dashboard children={<Account />} />}
                />
                <Route
                  exact
                  path="accounts/:accountNum/deposit"
                  element={<Dashboard children={<Deposit />} />}
                />
                <Route
                  exact
                  path="account-types"
                  element={<Dashboard children={<AccountTypes />} />}
                />
                <Route
                  exact
                  path="account-types/:id"
                  element={<Dashboard children={<AccountType />} />}
                />
                <Route
                  exact
                  path="account-transactions/:accountNumber"
                  element={<Dashboard children={<AccountTransactions />} />}
                />
                <Route
                  exact
                  path="transfer-transactions/:accountNumber"
                  element={<Dashboard children={<TransferTransactions />} />}
                />
                <Route
                  exact
                  path="biller-transactions/:accountNumber"
                  element={<Dashboard children={<BillerTransactions />} />}
                />
                <Route
                  exact
                  path="account-transactions/transaction/:id"
                  element={<Dashboard children={<AccountTransaction />} />}
                />
                <Route
                  exact
                  path="transfer-transactions/transaction/:id"
                  element={<Dashboard children={<TransferTransaction />} />}
                />
                <Route
                  exact
                  path="biller-transactions/transaction/:id"
                  element={<Dashboard children={<BillerTransaction />} />}
                />
                <Route
                  exact
                  path="transaction-reversals"
                  element={<Dashboard children={<TransactionReversals />} />}
                />
              </Route>

              <Route
                element={
                  <RequireAuth allowedPermissions={["ROOT", "CAN_LOGIN"]} />
                }
              >
                <Route
                  exact
                  path="profiles"
                  element={<Dashboard children={<Profiles />} />}
                />

                <Route
                  exact
                  path="profiles/:id"
                  element={<Dashboard children={<Profile />} />}
                />
                <Route
                  exact
                  path="profiles/:id/edit"
                  element={<Dashboard children={<EditProfile />} />}
                />
                <Route
                  exact
                  path="profiles/add"
                  element={<Dashboard children={<AddProfile />} />}
                />

                <Route
                  exact
                  path="requests"
                  element={<Dashboard children={<Requests />} />}
                />

                <Route
                  exact
                  path="modules/add"
                  element={<Dashboard children={<Apps />} />}
                />

                <Route
                  exact
                  path="accounts/add"
                  element={<Dashboard children={<AddAccount />} />}
                />
              </Route>
            </Route>
            {/* end PersistLogin */}

            {/* Public */}
            <Route exact path="login" element={<Login />} />

            <Route
              exact
              path="users/reset-password/:uvt"
              element={<ResetPassword />}
            />
            <Route exact path="/access-denied" element={<AccessDenied />} />
            <Route
              exact
              path="users/reset-password/initiate"
              element={<ResetPasswordLink />}
            />

            <Route exact path="users/verify-email" element={<VerifyEmail />} />

            {/* Catch all other routes */}
            <Route path="*" element={<Login />} />

            {/* TODO: add 404 route and page */}

            {/* TODO: remove experimental routes */}
            <Route
              exact
              path="sel"
              element={<Dashboard children={<SelectMultiple />} />}
            />
          </Routes>
        </BrowserRouter>
      </AppContext.Provider>
    </SnackbarProvider>
  );
};

export default App;
