import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import { DataGrid } from "@mui/x-data-grid";
import { AppContext } from "../helpers/contexts/AppContext";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const payRequestColumns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "amount",
    headerName: "Amount",
    width: 150,
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
  },
  {
    field: "responseCode",
    headerName: "Response Code",
    type: "number",
    width: 150,
  },
  {
    field: "service",
    headerName: "Service",
    description: "Service",
    sortable: false,
    width: 150,
  },
  {
    field: "currency",
    headerName: "Currency",
    description: "currency",
    sortable: false,
    width: 150,
  },
];

const cashoutRequestColumns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "amount",
    headerName: "Amount",
    width: 150,
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
  },
  {
    field: "account_number",
    headerName: "Account Number",
    type: "number",
    width: 150,
  },
];

const transLedgerColumns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "amount",
    headerName: "Amount",
    width: 150,
  },
  {
    field: "currency",
    headerName: "Currency",
    description: "currency",
    sortable: false,
    width: 150,
  },
  {
    field: "customer_account",
    headerName: "Customer Account",
    width: 150,
  },
  {
    field: "collection_account",
    headerName: "Collection Account",
    width: 150,
  },
  {
    field: "transaction_id",
    headerName: "Transaction ID",
    description: "Transaction ID",
    sortable: false,
    width: 150,
  },
  {
    field: "type",
    headerName: "Type",
    description: "Type",
    width: 150,
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={value}
      aria-labelledby={`${value}-tab`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2, height: "100vh" }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function Requests() {
  const [value, setValue] = React.useState(0);
  const [payRequests, setPayRequests] = React.useState([]);
  const [transLedger, setTransLedger] = React.useState([]);
  const [cashoutRequests, setcashoutRequests] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const { REACT_APP_ACCOUNTS_API_URL } = process.env;
  const payRequestsEndpoint = `payrequest/all`;
  const cashoutRequestsEndpoint = `cashoutrequest/all`;
  const transLedgerEndpoint = `ledger/all`;
  const axios = useAxiosPrivate();
  const { CustomExportToolbar, setExportFileName } =
    React.useContext(AppContext);
  const tabs = ["Pay Requests", "Cashout Requests", "Transaction Ledger"];
  const columns = [
    payRequestColumns,
    cashoutRequestColumns,
    transLedgerColumns,
  ];
  const rows = [payRequests, cashoutRequests, transLedger];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setExportFileName(tabs[newValue]);
  };

  const getPayRequests = () => {
    axios
      .get(`${REACT_APP_ACCOUNTS_API_URL}/${payRequestsEndpoint}/all`)
      .then((res) => {
        const { result } = res.data;
        setPayRequests(result);
      });
  };

  const getCashoutRequests = () => {
    axios
      .get(`${REACT_APP_ACCOUNTS_API_URL}/${cashoutRequestsEndpoint}/all`)
      .then((res) => {
        const { result } = res.data;
        setcashoutRequests(result);
      });
  };

  const getTransLedger = () => {
    axios
      .get(`${REACT_APP_ACCOUNTS_API_URL}/${transLedgerEndpoint}/all`)
      .then((res) => {
        const { result } = res.data;
        setTransLedger(result);
      });
  };

  React.useEffect(() => {
    setLoading(true);
    setExportFileName(tabs[0]);
    getPayRequests();
    getCashoutRequests();
    getTransLedger();
    setLoading(false);
  }, []);

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <Tabs value={value} onChange={handleChange} centered>
        {tabs.map((tab) => (
          <Tab label={tab} />
        ))}
      </Tabs>

      {tabs.map((_, index) => (
        <TabPanel value={value} index={index}>
          <DataGrid
            sx={{ px: 1 }}
            density="comfortable"
            components={{ Toolbar: CustomExportToolbar }}
            rows={rows[index]}
            columns={columns[index]}
            pageSize={10}
            rowsPerPageOptions={[50]}
            loading={loading}
            disableSelectionOnClick
          />
        </TabPanel>
      ))}
    </Box>
  );
}
