import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { enqueueSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const { REACT_APP_BASE_API_URL } = process.env;
const endpoint = "notification-channel";

export default function AddNotificationChannel() {
  const [channel, setChannel] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const axios = useAxiosPrivate();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event) => {
    const { value } = event.target;
    setChannel(value);
  };

  const add = (e) => {
    const data = new FormData(e.currentTarget);
    e.preventDefault();

    const channel_name = data.get("channel_name");

    let newChannel = { channel_name };

    setChannel({ newChannel });

    setOpen(false);
    axios
      .post(`${REACT_APP_BASE_API_URL}/${endpoint}`, newChannel)
      .then((res) => {
        if (res.status && res.status === 200) {
          enqueueSnackbar("Channel added successfully", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  return (
    <Grid
      item
      xs={12}
      lg={12}
      md={12}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      pb={1}
    >
      <Paper>
        <div>
          <Button onClick={handleOpen}>Add New Channel</Button>
          <Modal
            aria-labelledby="addChannel"
            aria-describedby="Form for adding new channel."
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <Typography
                  id="addUser"
                  variant="h6"
                  component="h2"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Enter Channel Details
                </Typography>

                <Box
                  mt={2}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                    m: 1,
                  }}
                  component="form"
                  onSubmit={add}
                >
                  <Grid container direction={"column"} spacing={2}>
                    <Grid item>
                      <TextField
                        fullWidth
                        label="Channel Name"
                        id="channel_name"
                        name="channel_name"
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>

                    <Grid
                      item
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        type="submit"
                        color="primary"
                      >
                        Add Channel
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Fade>
          </Modal>
        </div>
      </Paper>
    </Grid>
  );
}
