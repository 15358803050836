import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Tooltip } from "@mui/material";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import ConfirmDialog from "../container/ConfirmDialog/ConfirmDialog";
import { AppContext } from "../helpers/contexts/AppContext";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PermissionIcon from "./icons/Permission";
import { enqueueSnackbar } from "notistack";

export default function Modules() {
  const endpoint = "module/all";
  const appViewUrl = "modules";
  const { REACT_APP_BASE_API_URL: apiUrl } = process.env;

  const [modules, setModules] = React.useState([]);
  const axios = useAxiosPrivate();
  const {
    openConfirmDialog,
    handleOpenConfirmDialog,
    handleCloseConfirmDialog,
    CustomToolbar,
    auth,
  } = React.useContext(AppContext);

  const [confirmMessage, setConfirmMessage] = React.useState("");
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [action, setAction] = React.useState(null);
  const [actionPresent, setactionPresent] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [payload, setPayload] = React.useState(null);

  const navigate = useNavigate();

  const deleteModule = (id) => {
    axios
      .delete(`${apiUrl}/${endpoint}/${id}`)
      .then((res) => {
        if (res.status && res.status === 200) {
          setModules(modules.filter((app) => app.id !== id));
          enqueueSnackbar(`Module deleted successfully.`, {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  const addPermissions = (id, requestBody) => {
    axios
      .patch(`${apiUrl}/${endpoint}/${id}`, requestBody)
      .then((res) => {
        if (res.status && res.status === 200) {
          enqueueSnackbar(`Module permissions updated.`, {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  const handleView = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
    return navigate(`/${appViewUrl}/${thisRow.id}`);
  };

  const handleEdit = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

    navigate(`/modules/${thisRow.id}/edit`);
  };

  const handleAddPermission = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};
    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

    addPermissions(thisRow.id);
  };

  const handleDelete = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
    setConfirmMessage(
      `Are you sure you want to delete Module ${thisRow.app_name}?`
    );
    setDialogHeader("Delete Module");
    setactionPresent(true);
    setAction("delete");
    setPayload(thisRow.id);
    handleOpenConfirmDialog();
  };

  const actions = [
    {
      name: "view",
      onclick: (e, params) => handleView(e, params),
      icon: <VisibilityIcon />,
      color: "primary",
      role: "VIEW_MODULE",
    },
    {
      name: "edit",
      onclick: (e, params) => handleEdit(e, params),
      icon: <EditIcon />,
      color: "primary",
      role: "EDIT_MODULE",
    },
    {
      name: "delete",
      onclick: (e, params) => handleDelete(e, params),
      icon: <DeleteIcon />,
      color: "warning",
      role: "DELETE_MODULE",
    },
    {
      name: "permission",
      onclick: (e, params) => handleAddPermission(e, params),
      icon: <PermissionIcon />,
      color: "primary",
      role: "EDIT_MODULE_PERMISSIONS",
    },
  ];

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "module_name", headerName: "Name", width: 150 },
    { field: "module_desc", headerName: "Description", width: 400 },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => {
        return actions.map((action) =>
          auth?.permissions?.find(
            (perm) => action.role.includes(perm) || perm === "ROOT"
          ) ? (
            <Tooltip title={action.name}>
              <Button
                variant="outlined"
                startIcon={action.icon}
                sx={{ m: 1 }}
                onClick={(e) => action.onclick(e, params)}
                color={action.color}
                aria-label={action.label}
              >
                {action.label}
              </Button>
            </Tooltip>
          ) : (
            ""
          )
        );
      },
      width: 250,
    },
  ];

  React.useEffect(() => {
    setLoading(true);
    let apps = [];
    axios
      .get(`${apiUrl}/${endpoint}`)
      .then((res) => {
        const { result } = res.data;
        result.map((row) => {
          row.permissions = row.permissions.length;
          apps.push(row);
        });
        setModules(apps);
      })
      .then((_) => setLoading(false));
  }, []);

  return (
    <React.Fragment>
      {openConfirmDialog && (
        <ConfirmDialog
          openConfirmDialog={openConfirmDialog}
          handleOpenConfirmDialog={() => handleOpenConfirmDialog()}
          handleCloseConfirmDialog={handleCloseConfirmDialog}
          dialogHeader={dialogHeader}
          message={confirmMessage}
          actionPresent={actionPresent}
          action={action}
          handler={deleteModule}
          payload={payload}
        />
      )}

      <Button onClick={() => navigate("/modules/add")}>Add New Module</Button>
      <div style={{ width: "100%" }}>
        <DataGrid
          autoHeight
          rows={modules}
          columns={columns}
          rowsPerPageOptions={[20, 50, 100]}
          getRowHeight={() => "auto"}
          disableColumnMenu
          sx={{ m: 1 }}
          loading={loading}
          components={{ Toolbar: CustomToolbar }}
        />
      </div>
    </React.Fragment>
  );
}
