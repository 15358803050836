import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AppsIcon from "@mui/icons-material/Apps";
import ContactsIcon from "@mui/icons-material/Contacts";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import FiberPinIcon from "@mui/icons-material/FiberPin";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PeopleIcon from "@mui/icons-material/People";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import * as React from "react";
import AccountState from "./icons/AccountState";
import Biller from "./icons/Biller";
import Cashout from "./icons/Cashout";
import TransactionType from "./icons/TransactionType";

export const items = [
  {
    name: "Clients",
    icon: <ContactsIcon />,
    uri: "clients",
    role: "CAN_ACCESS_CLIENTS_MOD",
  },
  {
    name: "Users",
    icon: <PeopleIcon />,
    uri: "users",
    role: "CAN_ACCESS_USER_MOD",
  },
  {
    name: "Profiles",
    icon: <AccountCircleIcon />,
    uri: "profiles",
    role: "CAN_ACCESS_PROFILES_MOD",
  },
  {
    name: "Accounts",
    icon: <AccountBalanceWalletIcon />,
    uri: "accounts",
    role: "CAN_ACCESS_ACCOUNTS_MOD",
  },
  {
    name: "Pin Statuses",
    icon: <FiberPinIcon />,
    uri: "pinstatuses",
    role: "CAN_ACCESS_PIN_STATUSES_MOD",
  },
  {
    name: "Modules",
    icon: <AppsIcon />,
    uri: "modules",
    role: "CAN_ACCESS_MODULES_MOD",
  },
  {
    name: "Roles",
    icon: <AdminPanelSettingsIcon />,
    uri: "roles",
    role: "CAN_ACCESS_ROLES_MOD",
  },
  {
    name: "Notification Templates",
    icon: <NotificationAddIcon />,
    uri: "notification-templates",
    role: "CAN_ACCESS_NOTIFICATION_TEMPLATES_MOD",
  },
  {
    name: "Notification Channels",
    icon: <EditNotificationsIcon />,
    uri: "notification-channels",
    role: "CAN_ACCESS_NOTIFICATION_CHANNELS_MOD",
  },
  {
    name: "Notifications",
    icon: <NotificationsIcon />,
    uri: "notifications",
    role: "CAN_ACCESS_NOTIFICATIONS_MOD",
  },
  {
    name: "Account Types",
    icon: <AccountTreeIcon />,
    uri: "account-types",
    role: "CAN_ACCESS_ACCOUNT_TYPES_MOD",
  },
  {
    name: "Transaction Types",
    icon: <TransactionType />,
    uri: "transaction-types",
    role: "CAN_ACCESS_TRANSACTION_TYPES_MOD",
  },
  {
    name: "Account States",
    icon: <AccountState />,
    uri: "account-states",
    role: "CAN_ACCESS_ACCOUNT_STATES_MOD",
  },
  {
    name: "Cash Out Options",
    icon: <Cashout />,
    uri: "cashout-options",
    role: "CAN_ACCESS_CASHOUT_OPTIONS_MOD",
  },
  {
    name: "Billers",
    icon: <Biller />,
    uri: "billers",
    role: "CAN_ACCESS_BILLERS_MOD",
  },
  {
    name: "Requests",
    icon: <RequestPageIcon />,
    uri: "requests",
    role: "CAN_ACCESS_REQUESTS_MOD",
  },
];
