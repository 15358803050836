import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
// import axios from "axios";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../container/ConfirmDialog/ConfirmDialog";
import { AppContext } from "../helpers/contexts/AppContext";
import EditClient from "./EditClient";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import AddClient from "./AddClient";
import { enqueueSnackbar } from "notistack";

export default function Clients() {
  const endpoint = "client";
  const clientViewUrl = "clients";
  const { REACT_APP_BASE_API_URL } = process.env;
  const navigate = useNavigate();

  const [clients, setClients] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const {
    openConfirmDialog,
    handleOpenConfirmDialog,
    handleCloseConfirmDialog,
    openEditDialog,
    handleOpenEditDialog,
    handleCloseEditDialog,
    CustomToolbar,
    toggleBoolField,
    auth,
  } = React.useContext(AppContext);
  const [confirmMessage, setConfirmMessage] = React.useState("");
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [action, setAction] = React.useState(null);
  const [actionPresent, setactionPresent] = React.useState(false);
  const [editDialogHeader, setEditDialogHeader] = React.useState("");
  const [editActionPresent, setEditActionPresent] = React.useState(false);
  const [payload, setPayload] = React.useState(null);
  const [param, setParam] = React.useState(null);
  const [editRow, setEditRow] = React.useState({});
  const axiosPrivate = useAxiosPrivate();

  const deleteClient = (id) => {
    axiosPrivate
      .delete(`${REACT_APP_BASE_API_URL}/${endpoint}/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setClients(clients.filter((c) => c.id !== id));
        }
      });
  };

  const toggleActive = (id, state) => {
    axiosPrivate
      .patch(`${REACT_APP_BASE_API_URL}/${endpoint}/${id}`, {
        active: state,
      })
      .then((res) => {
        let updatedClient = clients.filter((c) => c.id === id)[0];
        let updatedClients = clients.map((c) => {
          if (c.id === id) {
            return updatedClient;
          }
          return c;
        });

        if (res.status && res.status === 200) {
          updatedClient.active = state;
          enqueueSnackbar("Client state updated successfully.", {
            variant: "success",
          });
          return setClients(updatedClients);
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  const editClient = (id, requestBody) => {
    let updatedRequestBody = { id };
    Object.keys(requestBody).map((key) => {
      if (key !== "action" || key !== "id") {
        updatedRequestBody[
          key.replace(/([A-Z])/g, ($1) => "_" + $1.toLowerCase())
        ] = requestBody[key];
      }
    });

    axiosPrivate
      .patch(`${REACT_APP_BASE_API_URL}/${endpoint}/${id}`, updatedRequestBody)
      .then((res) => {
        let updatedClient = clients.filter((c) => c.id === id)[0];
        Object.keys(requestBody).map((key) => {
          updatedClient[key] = updatedClient[key];
        });
        let updatedClients = clients.map((c) => {
          if (c.id === id) {
            return updatedClient;
          }
          return c;
        });

        if (res.status && res.status === 200) {
          enqueueSnackbar("Client updated successfully.", {
            variant: "success",
          });
          return setClients(updatedClients);
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  const handleToggleActive = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

    toggleActive(thisRow.id, !thisRow.active);
  };

  const handleEdit = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

    setEditRow(thisRow);
    setEditDialogHeader("Update Client Details");
    setEditActionPresent(true);
    setAction("edit");
    setParam(thisRow.id);
    handleOpenEditDialog();
  };

  const handleDelete = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
    setConfirmMessage(
      `Are you sure you want to delete client ${thisRow.clientName}?`
    );
    setDialogHeader("Delete Client");
    setactionPresent(true);
    setAction("delete");
    setPayload(thisRow.id);
    handleOpenConfirmDialog();
  };

  const handleView = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
    return navigate(`/${clientViewUrl}/${thisRow.id}`);
  };

  const actions = [
    {
      name: "view",
      onclick: (e, params) => handleView(e, params),
      icon: <VisibilityIcon />,
      color: "primary",
      role: "VIEW_CLIENT",
    },
    {
      name: "edit",
      onclick: (e, params) => handleEdit(e, params),
      icon: <EditIcon />,
      color: "primary",
      role: "EDIT_CLIENT",
    },
    {
      name: "activate",
      onclick: (e, params) => handleToggleActive(e, params),
      icon: {
        on: <ToggleOnIcon />,
        off: <ToggleOffIcon />,
      },
      color: {
        on: "success",
        off: "warning",
      },
      role: "ACTIVATE_CLIENT",
    },
    {
      name: "delete",
      onclick: (e, params) => handleDelete(e, params),
      icon: <DeleteIcon />,
      color: "warning",
      role: "DELETE_CLIENT",
    },
  ];

  const getData = () => {
    setLoading(true);
    axiosPrivate
      .get(`${REACT_APP_BASE_API_URL}/client/all`)
      .then((res) => {
        const { result } = res.data;
        setClients(result);
      })
      .then((_) => setLoading(false));
  };
  React.useEffect(() => {
    getData();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "client_name", headerName: "Name", width: 150 },
    { field: "primary_email", headerName: "Email", width: 200 },
    { field: "primary_tel", headerName: "Tel", width: 200 },
    { field: "active", headerName: "Active", width: 100 },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        return actions.map((action) =>
          auth?.permissions?.find(
            (perm) => action.role.includes(perm) || perm === "ROOT"
          ) ? (
            <Button
              onClick={(e) => action.onclick(e, params)}
              variant="outlined"
              size="small"
              color={
                action.name === "activate"
                  ? toggleBoolField(params, "active")
                    ? action.color.on
                    : action.color.off
                  : action.color
              }
              sx={{ m: 1 }}
            >
              {action.name === "activate"
                ? toggleBoolField(params, "active")
                  ? action.icon.on
                  : action.icon.off
                : action.icon}
            </Button>
          ) : (
            ""
          )
        );
      },
      width: 150,
    },
  ];

  return (
    <React.Fragment>
      {openConfirmDialog && (
        <ConfirmDialog
          openConfirmDialog={openConfirmDialog}
          handleOpenConfirmDialog={() => handleOpenConfirmDialog()}
          handleCloseConfirmDialog={handleCloseConfirmDialog}
          dialogHeader={dialogHeader}
          message={confirmMessage}
          actionPresent={actionPresent}
          action={action}
          handler={deleteClient}
          payload={payload}
          param={param}
        />
      )}

      {openEditDialog && (
        <EditClient
          openEditDialog={openEditDialog}
          handleOpenEditDialog={handleOpenEditDialog}
          handleCloseEditDialog={handleCloseEditDialog}
          dialogHeader={editDialogHeader}
          actionPresent={editActionPresent}
          action={action}
          handler={editClient}
          param={param}
          row={editRow}
        />
      )}
      <AddClient />
      <div style={{ width: "100%" }}>
        <DataGrid
          autoHeight
          rows={clients}
          columns={columns}
          rowsPerPageOptions={[20, 50, 100]}
          getRowHeight={() => "auto"}
          getRowId={(row) => row.id}
          disableColumnMenu
          loading={loading}
          components={{ Toolbar: CustomToolbar }}
        />
      </div>
    </React.Fragment>
  );
}
