import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { enqueueSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const { REACT_APP_BASE_API_URL } = process.env;
const endpoint = "notification";

export default function AddNNotification() {
  const [notification, setNotification] = React.useState({});
  const [selectedTemplate, setSelectedTemplate] = React.useState([]);
  const [template, setTemplate] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const axios = useAxiosPrivate();
  // Get templates
  const getPrePopulatedFields = () => {
    const templatesUrl = "notification-template/all";

    let temps = [];

    axios.get(`${REACT_APP_BASE_API_URL}/${templatesUrl}`).then((res) => {
      const { result } = res.data;
      result.map((template) => {
        temps.push(template);
      });
    });

    setTemplate(temps);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event) => {
    const { value, name } = event.target;
    switch (name) {
      case "notification_name":
        setNotification({ [value]: value });
        break;
      case "template":
        setSelectedTemplate(value);
        break;

      default:
        enqueueSnackbar(`Unknown field : ${name}`, {
          variant: "error",
        });
    }
  };

  const add = (e) => {
    const data = new FormData(e.currentTarget);
    e.preventDefault();

    const notification_name = data.get("notification_name");
    const template = data.get("template");

    if (!notification_name || !template) {
      enqueueSnackbar("Please fill all fields", {
        variant: "error",
      });
    }

    let newNotification = {};
    newNotification.notification_name = notification_name;
    newNotification.template = template;

    setNotification(newNotification);

    console.log(newNotification);
    setOpen(false);
    axios
      .post(
        `${process.env.REACT_APP_BASE_API_URL}/${endpoint}`,
        newNotification
      )
      .then((res) => {
        if (res.status && res.status === 200) {
          enqueueSnackbar("Notification added successfully.", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  React.useEffect(() => {
    getPrePopulatedFields();
  }, []);

  return (
    <Grid
      item
      xs={12}
      lg={12}
      md={12}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      pb={1}
    >
      <Paper>
        <div>
          <Button onClick={handleOpen}>Add New Notification</Button>
          <Modal
            aria-labelledby="addNotification"
            aria-describedby="add-notification"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <Typography
                  id="addNotification"
                  variant="h6"
                  component="h2"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Enter Notification Details
                </Typography>

                <Box
                  mt={2}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                    m: 1,
                  }}
                  component="form"
                  onSubmit={add}
                >
                  <Grid container direction={"column"} spacing={2}>
                    <Grid item>
                      <TextField
                        required
                        fullWidth
                        label="Notification Name"
                        id="notification_name"
                        name="notification_name"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item>
                      <FormControl required fullWidth>
                        <InputLabel id="demo-simple-select-required-label">
                          Template
                        </InputLabel>
                        <Select
                          required
                          labelId="template"
                          id="template"
                          value={selectedTemplate.id}
                          label="template *"
                          name="template"
                          onChange={handleChange}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>

                          {template.map((template, index) => (
                            <MenuItem value={template.id} key={index}>
                              {template.subject.length > 40
                                ? `${template.subject.slice(0, 36)}...`
                                : template.subject}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        type="submit"
                        color="primary"
                      >
                        Add Notification
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Fade>
          </Modal>
        </div>
      </Paper>
    </Grid>
  );
}
