import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import { someFiledsEmpty } from "../helpers/utils";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { enqueueSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const { REACT_APP_ACCOUNTS_API_URL } = process.env;
const endpoint = "account-type";

export default function AddAccountType() {
  const [open, setOpen] = React.useState(false);
  const [accountType, setAccountType] = React.useState("");
  const [maximumBalanceLimit, setMaxBalanceLimit] = React.useState(0);
  const [allowedTransactionsPerMin, setAllowedTransPerMin] = React.useState(0);
  const [transactionLimit, setTranLimit] = React.useState(0);
  const [allowedDailyDeposits, setAllowedDailyDeposits] = React.useState(0);
  const [allowedDailyWithdrawals, setAllowedDailyWithdrawals] =
    React.useState(0);
  const [allowedDailyTransferIns, setAllowedDailyTransIns] = React.useState(0);
  const [allowedDailyTransferOuts, setAllowedDailyTransOuts] =
    React.useState(0);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const axios = useAxiosPrivate();
  const handleChange = (event) => {
    const { value, name } = event.target;
    switch (name) {
      case "account_type":
        setAccountType(value);
        break;
      case "maximum_balance_limit":
        setMaxBalanceLimit(value);
        break;
      case "allowed_transactions_per_min":
        setAllowedTransPerMin(value);
        break;
      case "transaction_limit":
        setTranLimit(value);
        break;
      case "allowed_daily_deposits":
        setAllowedDailyDeposits(value);
        break;
      case "allowed_daily_withdrawals":
        setAllowedDailyWithdrawals(value);
        break;
      case "allowed_daily_transfer_ins":
        setAllowedDailyTransIns(value);
        break;
      case "allowed_daily_transfer_outs":
        setAllowedDailyTransOuts(value);
        break;
      default:
        enqueueSnackbar(`Unknown field : ${name}`, {
          variant: "error",
        });
    }
  };

  const add = (e) => {
    e.preventDefault();

    if (
      someFiledsEmpty([
        accountType,
        maximumBalanceLimit,
        allowedTransactionsPerMin,
        transactionLimit,
        allowedDailyDeposits,
        allowedDailyWithdrawals,
        allowedDailyTransferIns,
        allowedDailyTransferOuts,
      ])
    ) {
      enqueueSnackbar("Please complete all fields.", {
        variant: "error",
      });
    }

    setOpen(false);
    axios
      .post(`${REACT_APP_ACCOUNTS_API_URL}/${endpoint}`, {
        account_type: accountType,
        maximum_balance_limit: maximumBalanceLimit,
        allowed_transactions_per_min: allowedTransactionsPerMin,
        transaction_limit: transactionLimit,
        allowed_daily_deposits: allowedDailyDeposits,
        allowed_daily_withdrawals: allowedDailyWithdrawals,
        allowed_daily_transfer_ins: allowedDailyTransferIns,
        allowed_daily_transfer_outs: allowedDailyTransferOuts,
      })
      .then((res) => {
        if (res.status && res.status === 200) {
          enqueueSnackbar("Transaction Type added successfully.", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  return (
    <React.Fragment>
      <Grid
        item
        xs={12}
        lg={12}
        md={12}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        pb={1}
      >
        <Paper>
          <div>
            <Button onClick={handleOpen}>Add New Account Type</Button>
            <Modal
              aria-labelledby="addAccountType"
              aria-describedby="add new account type"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <Box sx={style}>
                  <Typography
                    id="addAccountType"
                    variant="h6"
                    component="h2"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Enter Account Type Details
                  </Typography>

                  <Box
                    mt={2}
                    sx={{
                      width: 500,
                      maxWidth: "100%",
                      m: 1,
                    }}
                    component="form"
                    onSubmit={add}
                  >
                    <Grid container direction={"column"} spacing={2}>
                      <Grid item>
                        <TextField
                          required
                          fullWidth
                          label="Account Type"
                          id="account_type"
                          name="account_type"
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item>
                        <TextField
                          required
                          fullWidth
                          label="Max. Balance"
                          id="maximum_balance_limit"
                          name="maximum_balance_limit"
                          type="number"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          required
                          fullWidth
                          label="Allowed Trans Per Min"
                          id="allowed_transactions_per_min"
                          name="allowed_transactions_per_min"
                          type="number"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          required
                          fullWidth
                          label="Transaction Limit"
                          id="transaction_limit"
                          name="transaction_limit"
                          type="number"
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item>
                        <TextField
                          required
                          fullWidth
                          label="Allowed Daily Deposits"
                          id="allowed_daily_deposits"
                          name="allowed_daily_deposits"
                          type="number"
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item>
                        <TextField
                          required
                          fullWidth
                          label="Allowed Daily Withdrawals"
                          id="allowed_daily_withdrawals"
                          name="allowed_daily_withdrawals"
                          type="number"
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item>
                        <TextField
                          required
                          fullWidth
                          label="Allowed Daily Trans Ins"
                          id="allowed_daily_transfer_ins"
                          name="allowed_daily_transfer_ins"
                          type="number"
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item>
                        <TextField
                          required
                          fullWidth
                          label="Allowed Daily Trans Outs"
                          id="allowed_daily_transfer_outs"
                          name="allowed_daily_transfer_outs"
                          type="number"
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid
                        item
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          variant="outlined"
                          startIcon={<AddIcon />}
                          type="submit"
                          color="primary"
                        >
                          Add New Account Type
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Fade>
            </Modal>
          </div>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}
