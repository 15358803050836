import { Link } from "react-router-dom";
import denied from './icons/access-denied.webp';
import * as React from "react";


export default function VerifyEmail() {
  return (
    <React.Fragment>
      <p >
        <Link style={{ textDecoration: "none", color: "blue" }} to="/">
          <img
            src={denied}
            alt="logo"
            class="center"
          />
        </Link>
      </p>
    </React.Fragment>
  );
}
