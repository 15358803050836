import { Button, Chip, Grid, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../helpers/contexts/AppContext";
import { enqueueSnackbar } from "notistack";

const style = {
  width: "100%",
  display: "flex",
  bgcolor: "background.paper",
  flexDirection: "column",
};

const { REACT_APP_ACCOUNTS_API_URL } = process.env;
const endpoint = "transaction-type";
const redirectUrl = "/transaction-types";

export default function TransactionType() {
  const [type, setType] = useState({});
  const axios = useAxiosPrivate();
  const navigate = useNavigate();
  const { deleteAndRedirect } = useContext(AppContext);

  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`${REACT_APP_ACCOUNTS_API_URL}/${endpoint}/${id}`)
      .then((res) => {
        const { result } = res.data;
        setType(result);
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  }, []);

  return (
    <Grid sx={{ display: "flex", flexDirection: "column" }}>
      <List sx={style} component="nav" aria-label="Profile Details">
        <ListItem button>
          <ListItemText primary="Type" />
          <Typography>{type.type}</Typography>
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText primary="Instruction" />
          <Typography>{type.instruction}</Typography>
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText primary="Charge" />
          <Typography>{type.charge}</Typography>
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText primary="Charge Type" />
          <Typography>{type.charge_type}</Typography>
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText primary="Short Form" />
          <Typography>{type.short_form}</Typography>
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText primary="Is Biller Service" />
          <Typography>{type.is_biller_service}</Typography>
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText primary="Service URL" />
          <Typography>{type.service_url}</Typography>
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText primary="Allowed PIN Statuses" mr={1} />
          {type.allowed_pin_statuses && type.allowed_pin_statuses.length > 5 ? (
            <ListItem>
              <Typography
                sx={
                  type.allowed_pin_statuses &&
                  type.allowed_pin_statuses.length === 0
                    ? { fontStyle: "italic", fontWeight: 100 }
                    : {}
                }
              >
                {type.allowed_pin_statuses &&
                type.allowed_pin_statuses.length > 0
                  ? type.allowed_pin_statuses.map((status) => (
                      <Chip
                        label={status.status}
                        variant="outlined"
                        sx={{ m: 1 }}
                        color="primary"
                      />
                    ))
                  : `No PIN statuses added.`}
              </Typography>
            </ListItem>
          ) : (
            <Typography
              sx={
                type.allowed_pin_statuses &&
                type.allowed_pin_statuses.length === 0
                  ? { fontStyle: "italic", fontWeight: 100 }
                  : {}
              }
            >
              {type.allowed_pin_statuses && type.allowed_pin_statuses > 0
                ? type.allowed_pin_statuses.map((status) => (
                    <Chip
                      label={status.status}
                      variant="outlined"
                      sx={{ m: 1 }}
                      color="primary"
                    />
                  ))
                : `No PIN statuses added.`}
            </Typography>
          )}
        </ListItem>

        <Divider />
      </List>

      <Grid sx={{ mt: 2, justifyContent: "center" }}>
        <Button
          variant="outlined"
          sx={{ m: 0.5 }}
          aria-label="export-transactions"
          onClick={() => navigate(redirectUrl)}
        >
          View All Transaction Type
        </Button>
        <Button
          variant="outlined"
          sx={{ m: 0.5 }}
          aria-label="export-transactions"
          color="warning"
          onClick={() =>
            deleteAndRedirect(
              id,
              `${REACT_APP_ACCOUNTS_API_URL}/${endpoint}`,
              "Transaction Type",
              true,
              navigate,
              redirectUrl
            )
          }
        >
          Delete This Transaction Types
        </Button>
      </Grid>
    </Grid>
  );
}
