import ChromeReaderModeIcon from "@mui/icons-material/ChromeReaderMode";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../container/ConfirmDialog/ConfirmDialog";
import { AppContext } from "../helpers/contexts/AppContext";
import { enqueueSnackbar } from "notistack";

export default function TransactionType() {
  const endpoint = "transaction-type";
  const viewUrl = "transaction-types";
  const { REACT_APP_ACCOUNTS_API_URL } = process.env;

  const axios = useAxiosPrivate();
  const [transactiontypes, setTransactionTypes] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const {
    openConfirmDialog,
    handleOpenConfirmDialog,
    handleCloseConfirmDialog,
    viewFromTable,
    auth,
  } = React.useContext(AppContext);

  const [confirmMessage, setConfirmMessage] = React.useState("");
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [action, setAction] = React.useState(null);
  const [actionPresent, setactionPresent] = React.useState(false);
  const [payload, setPayload] = React.useState(null);

  const navigate = useNavigate();

  const deleteAccountType = (id) => {
    axios
      .delete(`${REACT_APP_ACCOUNTS_API_URL}/${endpoint}/${id}`)
      .then((res) => {
        if (res.status && res.status === 200) {
          setTransactionTypes(transactiontypes.filter((t) => t.id !== id));
          enqueueSnackbar(`Transaction type deleted successfully.`, {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  const handleEdit = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

    navigate(`/transaction-types/${thisRow.id}/edit`);
  };

  const handleDelete = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
    setConfirmMessage(
      `Are you sure you want to delete transaction type ${thisRow.type}?`
    );
    setDialogHeader("Delete Transaction Type");
    setactionPresent(true);
    setAction("delete");
    setPayload(thisRow.id);
    handleOpenConfirmDialog();
  };

  const actions = [
    {
      name: "view",
      onclick: (e, params) => viewFromTable(e, params, navigate, viewUrl),
      icon: <ChromeReaderModeIcon />,
      color: "primary",
      role: "VIEW_TRANSACTION_TYPE",
    },
    {
      name: "edit",
      onclick: (e, params) => handleEdit(e, params),
      icon: <EditIcon />,
      color: "primary",
      role: "EDIT_TRANSACTION_TYPE",
    },
    {
      name: "delete",
      onclick: (e, params) => handleDelete(e, params),
      icon: <DeleteIcon />,
      color: "warning",
      role: "DELETE_TRANSACTION_TYPE",
    },
  ];

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "type", headerName: "Type", width: 200 },
    { field: "charge", headerName: "Charge", width: 100 },
    { field: "charge_type", headerName: "Charge Type", width: 200 },
    { field: "instruction", headerName: "Instruction", width: 150 },
    { field: "short_form", headerName: "Abbr", width: 100 },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => {
        return actions.map((action) =>
          auth?.permissions?.find(
            (perm) => action.role.includes(perm) || perm === "ROOT"
          ) ? (
            <Tooltip title={action.name}>
              <Button
                variant="outlined"
                startIcon={action.icon}
                sx={{ m: 1 }}
                onClick={(e) => action.onclick(e, params)}
                color={action.color}
                aria-label={action.label}
                key={action.name}
              >
                {action.label}
              </Button>
            </Tooltip>
          ) : (
            ""
          )
        );
      },
      width: 250,
    },
  ];

  React.useEffect(() => {
    setLoading(true);
    let types = [];
    axios
      .get(`${REACT_APP_ACCOUNTS_API_URL}/${endpoint}/all`)
      .then((res) => {
        const { result } = res.data;
        result.map((row) => {
          types.push(row);
        });
        setTransactionTypes(types);
      })
      .then((_) => setLoading(false));
  }, []);

  return (
    <React.Fragment>
      {openConfirmDialog && (
        <ConfirmDialog
          openConfirmDialog={openConfirmDialog}
          handleOpenConfirmDialog={() => handleOpenConfirmDialog()}
          handleCloseConfirmDialog={handleCloseConfirmDialog}
          dialogHeader={dialogHeader}
          message={confirmMessage}
          actionPresent={actionPresent}
          action={action}
          handler={deleteAccountType}
          payload={payload}
        />
      )}

      <Button
        sx={{ mb: 1 }}
        size="small"
        onClick={() => navigate("/transaction-types/add")}
      >
        Add New Transaction Type
      </Button>
      <div style={{ width: "100%" }}>
        <DataGrid
          autoHeight
          rows={transactiontypes}
          columns={columns}
          rowsPerPageOptions={[20, 50, 100]}
          getRowHeight={() => "auto"}
          disableColumnMenu
          loading={loading}
        />
      </div>
    </React.Fragment>
  );
}
