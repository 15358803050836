import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { DataGrid } from "@mui/x-data-grid";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../helpers/contexts/AppContext";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const payRequestColumns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "amount",
    headerName: "Amount",
    width: 150,
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
  },
  {
    field: "responseCode",
    headerName: "Response Code",
    type: "number",
    width: 150,
  },
  {
    field: "service",
    headerName: "Service",
    description: "Service",
    sortable: false,
    width: 150,
  },
  {
    field: "currency",
    headerName: "Currency",
    description: "currency",
    sortable: false,
    width: 150,
  },
];

const cashoutRequestColumns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "amount",
    headerName: "Amount",
    width: 150,
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
  },
  {
    field: "account_number",
    headerName: "Account Number",
    type: "number",
    width: 150,
  },
];

const accountTransactionsColumns = [
  { field: "id", headerName: "ID", width: 100 },
  {
    field: "amount",
    headerName: "Amount",
    width: 150,
  },
  {
    field: "currency",
    headerName: "Currency",
    description: "currency",
    sortable: false,
    width: 100,
  },
  {
    field: "charge",
    headerName: "Charge",
    description: "Charge",
    width: 100,
  },
  {
    field: "transactionType",
    headerName: "Transaction Type",
    width: 150,
  },
  {
    field: "starting_balance",
    headerName: "Starting Balance",
    width: 150,
  },
  {
    field: "closing_balance",
    headerName: "Closing Balance",
    width: 150,
  },
  {
    field: "transaction_id",
    headerName: "Transaction ID",
    description: "Transaction ID",
    sortable: false,
    width: 150,
  },
];

const transferTransactionsColumns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "amount",
    headerName: "Amount",
    width: 120,
  },
  {
    field: "currency",
    headerName: "Currency",
    description: "currency",
    sortable: false,
    width: 70,
  },
  {
    field: "service_charge",
    headerName: "Charge",
    description: "Service Charge",
    width: 100,
  },
  {
    field: "from_account",
    headerName: "From Acc",
    width: 150,
  },
  {
    field: "to_account",
    headerName: "To Acc",
    width: 150,
  },

  {
    field: "transactionType",
    headerName: "Trans. Type",
    width: 100,
  },
  {
    field: "from_account_starting_balance",
    headerName: "From Acc Starting Balance",
    width: 150,
  },
  {
    field: "from_account_closing_balance",
    headerName: "From Acc Closing Balance",
    width: 150,
  },

  {
    field: "to_account_starting_balance",
    headerName: "To Acc Starting Balance",
    width: 150,
  },
  {
    field: "to_account_closing_balance",
    headerName: "To Acc Closing Balance",
    width: 150,
  },
  {
    field: "narration",
    headerName: "Narration",
    width: 150,
  },

  {
    field: "reversed",
    headerName: "Reversed",
    width: 70,
  },

  {
    field: "transaction_id",
    headerName: "Transaction ID",
    description: "Transaction ID",
    sortable: false,
    width: 150,
  },
];

const billerTransactionsColumns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "narration",
    headerName: "Narration",
    sortable: false,
    width: 200,
  },
  {
    field: "customer_account",
    headerName: "Customer Acc",
    width: 150,
  },
  {
    field: "biller_account",
    headerName: "Biller Account",
    description: "Biller Account",
    width: 150,
  },
  {
    field: "transaction_id",
    headerName: "Transaction ID",
    type: "number",
    width: 150,
  },

  {
    field: "reversed",
    headerName: "Reversed",
    description: "Reversed",
    width: 150,
  },
  {
    field: "service_charge",
    headerName: "Service Charge",
    description: "Service Charge",
    sortable: false,
    width: 150,
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={value}
      aria-labelledby={`${value}-tab`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2, height: "100vh" }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// (Default will be acc transactions) ++
// Acc trans (add reverse action, maybe not if the UX is not good)? ++
// Biller trans
// Pay requests +
// Transfer trans ++
// Cashout requests ++

const Homepage = () => {
  const [value, setValue] = useState(0);
  const [payRequests, setPayRequests] = useState([]);
  const [accountTransactions, setAccountTransactions] = useState([]);
  const [cashoutRequests, setcashoutRequests] = useState([]);
  const [transferTransactions, setTransferTransactions] = useState([]);
  const [billerTransactions, setBillerTransactions] = useState([]);
  const [loading, setLoading] = React.useState(false);

  const { REACT_APP_ACCOUNTS_API_URL } = process.env;
  const payRequestsEndpoint = `payrequest/dated`;
  const cashoutRequestsEndpoint = `cashoutrequest/dated`;
  const accountTransactionsEndpoiint = `account-transaction/dated`;
  const transferTransactionsEndpoint = `transfer-transaction/dated`;
  const billerTransactionsEndpoint = `transfer-transaction/dated`;

  const { CustomExportToolbar, setExportFileName, auth } =
    useContext(AppContext);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const date = new Date(); //.toISOString();//toLocaleDateString("en-CA"); // Get date in yyyy-mm-dd format
  const today =
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  const tabs = [
    "Account Transactions",
    "Pay Requests",
    "Transfer Transactions",
    "Cashout Requests",
    "Biller Transactions",
  ];
  const columns = [
    accountTransactionsColumns,
    payRequestColumns,
    transferTransactionsColumns,
    cashoutRequestColumns,
    billerTransactionsColumns, //TODO: Replace with biller trans. columns
  ];
  const rows = [
    accountTransactions,
    payRequests,
    transferTransactions,
    cashoutRequests,
    billerTransactions, //TODO: Replace with biller trans. data
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setExportFileName(tabs[newValue]);
  };

  const getPayRequests = () => {
    axiosPrivate
      .get(
        `${REACT_APP_ACCOUNTS_API_URL}/${payRequestsEndpoint}?start-date=${today}&end-date=${today}`
      )
      .then((res) => {
        const { result } = res.data;
        setPayRequests(result);
      });
  };

  const getCashoutRequests = () => {
    axiosPrivate
      .get(
        `${REACT_APP_ACCOUNTS_API_URL}/${cashoutRequestsEndpoint}?start-date=${today}&end-date=${today}`
      )
      .then((res) => {
        const { result } = res.data;
        setcashoutRequests(result);
      });
  };

  const getAccountTransactions = () => {
    axiosPrivate
      .get(
        `${REACT_APP_ACCOUNTS_API_URL}/${accountTransactionsEndpoiint}?start-date=${today}&end-date=${today}`
      )
      .then((res) => {
        const { result } = res.data;
        let updatedTrans = [];
        result.map((transaction) => {
          transaction.transaction_type = transaction.transaction_type.type;
          updatedTrans.push(transaction);
        });
        setAccountTransactions(updatedTrans);
      });
  };

  const getTransferTransactions = () => {
    axiosPrivate
      .get(
        `${REACT_APP_ACCOUNTS_API_URL}/${transferTransactionsEndpoint}?start-date=${today}&end-date=${today}`
      )
      .then((res) => {
        const { result } = res.data;
        let updatedTrans = [];
        result.map((transaction) => {
          transaction.transaction_type = transaction.transaction_type.type;
          updatedTrans.push(transaction);
        });
        setTransferTransactions(updatedTrans);
      });
  };

  const getBillerTransactions = () => {
    axiosPrivate
      .get(
        `${REACT_APP_ACCOUNTS_API_URL}/${billerTransactions}?start-date=${today}&end-date=${today}`
      )
      .then((res) => {
        const { result } = res.data;
        let updatedTrans = [];
        result.map((transaction) => {
          transaction.transaction_type = transaction.transaction_type.type;
          updatedTrans.push(transaction);
        });
        setTransferTransactions(updatedTrans);
      });
  };

  useEffect(() => {
    console.log("Testing testing");
    setLoading(true);
    console.log("Testing testing");
    setExportFileName(tabs[0]);
    getPayRequests();
    getCashoutRequests();
    getAccountTransactions();
    getTransferTransactions();

    if (_.isEmpty(auth?.permissions)) {
      return navigate("/access-denied");
    }
    setLoading(false);
  }, []);

  return (
    <React.Fragment>
      {loading ? (
        "Loading..."
      ) : (
        <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
          <Tabs value={value} onChange={handleChange} centered>
            {tabs.map((tab) => (
              <Tab label={tab} />
            ))}
          </Tabs>

          {tabs.map((_, index) => (
            <TabPanel value={value} index={index}>
              <DataGrid
                sx={{ px: 1 }}
                density="comfortable"
                components={{ Toolbar: CustomExportToolbar }}
                rows={rows[index]}
                columns={columns[index]}
                pageSize={10}
                rowsPerPageOptions={[50]}
                disableSelectionOnClick
                loading={loading}
              />
            </TabPanel>
          ))}
        </Box>
      )}
    </React.Fragment>
  );
};

export default Homepage;
