import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import { FormGroup, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { enqueueSnackbar } from "notistack";

export default function EditModule() {
  const [module, setModule] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const axios = useAxiosPrivate();
  const [permissions, setPermissions] = React.useState([]);
  const [permissionName, setPermissionName] = React.useState([]);

  // User fields
  const [description, setDescription] = React.useState("");
  const [name, setName] = React.useState("");

  const endpoint = "module";
  const appsViewUrl = "/modules";

  const permissionsEndpoint = "module/all";

  const { REACT_APP_BASE_API_URL } = process.env;
  const navigate = useNavigate();
  const { id } = useParams();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const updateModule = (e) => {
    e.preventDefault();
    axios
      .patch(`${REACT_APP_BASE_API_URL}/${endpoint}/${id}`, {
        permissions: _.join(permissionName, ","),
        module_name: name,
        module_desc: description,
      })
      .then((res) => {
        if (res.status === 200 || res.status === 206) {
          enqueueSnackbar("Module details updated successfully.", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    switch (name) {
      case "module_name":
        setName(value);
        break;
      case "module_desc":
        setDescription(value);
        break;
      default:
        alert(`Unknown field : ${event.target.name}`);
    }
  };

  const handlePermissionChange = (event) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved = [];

    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o === item) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x === item);
      } else {
        duplicateRemoved.push(item);
      }
    });
    setPermissionName(duplicateRemoved);
  };

  // Get all roles, permissions and notifications.
  // Get user's current roles, permissions and notifications.

  const getPrePopulatedFields = () => {
    let permissionNames = [];

    setLoading(true);

    axios
      .get(`${REACT_APP_BASE_API_URL}/${permissionsEndpoint}`)
      .then((res) => {
        const { result } = res.data;
        result.map((app) => {
          app.permissions.map((permission) =>
            permissionNames.push(permission.perm)
          );
        });
      });

    axios
      .get(`${REACT_APP_BASE_API_URL}/${endpoint}/${id}`)
      .then((res) => {
        const { result } = res.data;
        setModule(result);
        setName(result.app_name);
        setDescription(result.app_desc);

        return result;
      })
      .then((result) => {
        let updatedAppPerms = [];

        _.map(result.permissions, (p) => {
          updatedAppPerms.push(p.perm);
        });

        setPermissionName(updatedAppPerms);
        setPermissions(updatedAppPerms);
      })
      .then((_) => setLoading(false));
  };

  React.useEffect(() => {
    getPrePopulatedFields();
  }, []);

  return (
    <React.Fragment>
      {loading ? (
        "LOADING..."
      ) : (
        <Box mt={2} component="form" onSubmit={updateModule}>
          <FormGroup>
            <TextField
              fullWidth
              required
              margin="dense"
              id="module_name"
              name="module_name"
              label="Name"
              variant="outlined"
              defaultValue={module.module_name}
              onChange={handleChange}
            />

            <TextField
              fullWidth
              required
              margin="dense"
              id="module_desc"
              name="module_desc"
              label="Description"
              variant="outlined"
              defaultValue={module.module_desc}
              onChange={handleChange}
            />

            <FormControl fullWidth sx={{ mb: 1 }}>
              <InputLabel id="permissions">Permissions</InputLabel>
              <Select
                labelId="permissions"
                id="permissions"
                name="permissions"
                multiple
                value={permissionName}
                onChange={handlePermissionChange}
                input={<OutlinedInput label="Permissions" />}
                renderValue={(selected) =>
                  _.toString(selected).length > 30
                    ? _.join(selected, ", ").slice(0, 33).concat("...")
                    : selected.map((x) => x).join(", ")
                }
                MenuProps={MenuProps}
              >
                {permissions.map((permission, index) => (
                  <MenuItem key={index} value={permission}>
                    <Checkbox
                      checked={
                        permissionName.findIndex(
                          (item) => item === permission
                        ) >= 0
                      }
                    />
                    <ListItemText primary={permission} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Grid item sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="outlined"
                startIcon={<CheckIcon />}
                type="submit"
                color="primary"
                sx={{ m: 1 }}
              >
                Save
              </Button>

              <Button
                variant="outlined"
                startIcon={<CancelIcon />}
                color="warning"
                sx={{ m: 1 }}
                onClick={() => navigate(`${appsViewUrl}`)}
              >
                Cancel
              </Button>
            </Grid>
          </FormGroup>
        </Box>
      )}
    </React.Fragment>
  );
}
