import AddIcon from "@mui/icons-material/Add";
import { Grid } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Fade from "@mui/material/Fade";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import * as React from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { enqueueSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const { REACT_APP_BASE_API_URL } = process.env;
const endpoint = "notification-template";

export default function AddNotificationTemplate() {
  const [template, setTemplate] = React.useState({});
  const [subject, setSubject] = React.useState("");
  const [body, setBody] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [channels, setChannels] = React.useState([]);
  const [channelName, setChannelName] = React.useState([]);
  const axios = useAxiosPrivate();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // Get permission
  const getPrePopulatedFields = () => {
    const channelsUrl = "notification-channel/all";

    let channelNames = [];
    axios.get(`${REACT_APP_BASE_API_URL}/${channelsUrl}`).then((res) => {
      const { result } = res.data;
      result.map((channel) => {
        channelNames.push(channel.channel_name);
      });
    });

    setChannels(channelNames);
  };

  const handleChannelChange = (event) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved = [];

    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o === item) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x === item);
      } else {
        duplicateRemoved.push(item);
      }
    });
    setChannelName(duplicateRemoved);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event) => {
    const { value, name } = event.target;

    switch (name) {
      case "subject":
        setSubject(value);
        break;
      case "body":
        setBody(value);
        break;
      default:
        enqueueSnackbar(`Unknown field : ${name}`, {
          variant: "error",
        });
    }
  };

  const add = (e) => {
    const data = new FormData(e.currentTarget);
    e.preventDefault();

    const chans = data.get("channels");
    const subject = data.get("subject");
    const body = data.get("body");

    if (!body || !subject || !chans) {
      enqueueSnackbar("Please fill all fields", {
        variant: "error",
      });
    }

    let newTemplate = {};

    newTemplate.channels = _.split(chans, ",").toString();
    newTemplate.subject = subject;
    newTemplate.body = body;
    setTemplate(newTemplate);

    console.log(newTemplate);
    setOpen(false);
    axios
      .post(`${REACT_APP_BASE_API_URL}/${endpoint}`, newTemplate)
      .then((res) => {
        if (res.status && res.status === 200) {
          enqueueSnackbar("Notification Template added successfully.", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  React.useEffect(() => {
    getPrePopulatedFields();
  }, []);

  return (
    <Grid
      item
      xs={12}
      lg={12}
      md={12}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      pb={1}
    >
      <Paper>
        <div>
          <Button onClick={handleOpen}>Add New Template</Button>
          <Modal
            aria-labelledby="addTemplate"
            aria-describedby="Form for adding new template."
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <Typography
                  id="addTemplate"
                  variant="h6"
                  component="h2"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Enter Template Details
                </Typography>

                <Box
                  mt={2}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                    m: 1,
                  }}
                  component="form"
                  onSubmit={add}
                >
                  <Grid container direction={"column"} spacing={2}>
                    <Grid item>
                      <TextField
                        fullWidth
                        label="Subject"
                        id="subject"
                        name="subject"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        fullWidth
                        label="Body"
                        id="body"
                        name="body"
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item>
                      <FormControl fullWidth>
                        <InputLabel id="channels">Channels</InputLabel>
                        <Select
                          labelId="add-channels"
                          id="channels"
                          name="channels"
                          multiple
                          value={channelName}
                          onChange={handleChannelChange}
                          input={<OutlinedInput label="Channels" />}
                          renderValue={(selected) =>
                            _.toString(selected).length > 30
                              ? _.join(selected, ", ")
                                  .slice(0, 33)
                                  .concat("...")
                              : selected.map((x) => x).join(", ")
                          }
                          MenuProps={MenuProps}
                        >
                          {channels.map((channel, index) => (
                            <MenuItem key={index} value={channel}>
                              <Checkbox
                                checked={
                                  channelName.findIndex(
                                    (item) => item === channel
                                  ) >= 0
                                }
                              />
                              <ListItemText primary={channel} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        type="submit"
                        color="primary"
                      >
                        Add Template
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Fade>
          </Modal>
        </div>
      </Paper>
    </Grid>
  );
}
