import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { Grid } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { enqueueSnackbar } from "notistack";

import useAxiosPrivate from "../hooks/useAxiosPrivate";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const transferEndpoint = "transaction/admin/transfer";
const { REACT_APP_ACCOUNTS_API_URL } = process.env;
export default function Transfer({ sourceAccountNum }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const axios = useAxiosPrivate();
  const transfer = (e) => {
    setOpen(false);
    const data = new FormData(e.currentTarget);
    e.preventDefault();
    const to_account_number = data.get("to_account_number");
    const amount = data.get("amount");
    const narration = data.get("narration");
    const currency = data.get("currency");

    axios
      .post(`${REACT_APP_ACCOUNTS_API_URL}/${transferEndpoint}`, {
        from_account_number: sourceAccountNum,
        to_account_number,
        amount,
        narration,
        currency,
      })
      .then((res) => {
        if (res.status && res.status === 200) {
          enqueueSnackbar("Transfer successful", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  return (
    <Grid
      item
      xs={12}
      lg={12}
      md={12}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Paper>
        <div>
          <Button
            onClick={handleOpen}
            startIcon={<CurrencyExchangeIcon />}
          ></Button>
          <Modal
            aria-labelledby="transfer-modal"
            aria-describedby="Transfer funds to another account"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <Typography
                  id="transfer-form"
                  variant="h6"
                  component="h2"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Enter Details
                </Typography>

                <Box
                  mt={2}
                  sx={{
                    m: 1,
                    width: 500,
                    maxWidth: "100%",
                  }}
                  component="form"
                  onSubmit={transfer}
                >
                  <Grid container direction={"column"} spacing={2}>
                    <Grid item>
                      <TextField
                        fullWidth
                        required
                        label="To (Account Number)"
                        id="to_account_number"
                        name="to_account_number"
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        required
                        fullWidth
                        label="Amount"
                        id="amount"
                        name="amount"
                        type="number"
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        required
                        fullWidth
                        label="Narration"
                        id="narration"
                        name="narration"
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        required
                        fullWidth
                        label="Currency"
                        id="currency"
                        name="currency"
                        defaultValue={"ZMW"}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        startIcon={<CurrencyExchangeIcon />}
                      >
                        Transfer
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Fade>
          </Modal>
        </div>
      </Paper>
    </Grid>
  );
}
