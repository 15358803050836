import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function SelectMultiple({ data, open, handleClose }) {
  const [checked, setChecked] = React.useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Update multiple values"
        aria-describedby="modal for updating multiple select values."
      >
        <Box sx={style}>
          <List
            sx={{
              width: "100%",
              maxWidth: 250,
              bgcolor: "background.paper",
            }}
          >
            {data.map((value) => {
              const labelId = `${value.app_name}`;
              return (
                <React.Fragment>
                  <Typography>{value.app_name}</Typography>
                  <ListItem key={value.perm} disablePadding>
                    <ListItemButton
                      role={undefined}
                      onClick={handleToggle(value.perm)}
                      dense
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checked.indexOf(value.perm) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={value.perm} />
                    </ListItemButton>
                  </ListItem>
                </React.Fragment>
              );
            })}
          </List>
          <Grid item sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              type="submit"
              color="primary"
            >
              Add Permissions
            </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
