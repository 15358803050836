import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { FormGroup, Grid } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { someFiledsEmpty } from "../helpers/utils";
import Typography from "@mui/material/Typography";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import _ from "lodash";
import { enqueueSnackbar } from "notistack";

export default function EditBiller() {
  const [loading, setLoading] = React.useState(false);
  const [billerName, setBillerName] = React.useState("");
  const [active, setActive] = React.useState(null);
  const [services, setServices] = React.useState([]);
  const [autoSettle, setAutoSettle] = React.useState(null);
  const [settlementAccount, setSettlementAccount] = React.useState("");
  const [settlementOption, setSettlementOption] = React.useState("");
  const [biller, setBiller] = React.useState({});
  const [serviceNames, setServiceName] = React.useState([]);

  const axios = useAxiosPrivate();
  const endpoint = "biller";
  const billersViewUrl = "/billers";
  const servicesEndpoint = "transaction-type/all";

  const { REACT_APP_ACCOUNTS_API_URL } = process.env;
  const navigate = useNavigate();
  const { id } = useParams();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const toggleIcons = {
    on: { icon: <ToggleOnIcon />, color: "success" },
    off: { icon: <ToggleOffIcon />, color: "warning" },
  };

  const handleServiceChange = (event) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved = [];

    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o === item) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x === item);
      } else {
        duplicateRemoved.push(item);
      }
    });
    setServiceName(duplicateRemoved);
  };

  const toggleAutoSettle = () => {
    setAutoSettle(!autoSettle);
  };

  const toggleActive = () => {
    setActive(!active);
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    switch (name) {
      case "biller_name":
        setBillerName(value);
        break;
      case "services":
        setServices(value);
        break;
      case "settlement_account_number":
        setSettlementAccount(value);
        break;
      case "settlement_option":
        setSettlementOption(value);
        break;
      default:
        enqueueSnackbar(`Unknown field : ${name}`, {
          variant: "error",
        });
    }
  };

  const updateBiller = (e) => {
    e.preventDefault();

    if (
      someFiledsEmpty([
        billerName,
        active,
        services,
        autoSettle,
        settlementAccount,
        settlementOption,
      ])
    ) {
      enqueueSnackbar("Please complete all fields.", {
        variant: "error",
      });
    }

    axios
      .patch(`${REACT_APP_ACCOUNTS_API_URL}/${endpoint}/${id}`, {
        biller_name: billerName,
        active,
        services: _.join(serviceNames, ","),
        auto_settle: autoSettle,
        settlement_account_number: settlementAccount,
        settlement_option: settlementOption,
      })
      .then((res) => {
        if (res.status && res.status === 200) {
          enqueueSnackbar("Biller updated successfully.", {
            variant: "success",
          });

          navigate(billersViewUrl);
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  // Get biller details.

  React.useEffect(() => {
    setLoading(true);

    let serviceNames = [];
    let updatedBillerServices = [];

    setLoading(true);

    axios
      .get(`${REACT_APP_ACCOUNTS_API_URL}/${servicesEndpoint}`)
      .then((res) => {
        const { result } = res.data;
        result.map((service) => {
          service.is_biller_service && serviceNames.push(service.type);
        });
      });

    axios
      .get(`${REACT_APP_ACCOUNTS_API_URL}/${endpoint}/${id}`)
      .then((res) => {
        const { result } = res.data;
        return result;
      })
      .then((result) => {
        _.map(result.services, (s) => {
          updatedBillerServices.push(s.type);
        });

        return result;
      })
      .then((biller) => {
        setBiller(biller);
        setActive(biller.active);
        setBillerName(biller.biller_name);
        setSettlementAccount(biller.settlement_account_number);
        setSettlementOption(biller.settlement_option);
        setAutoSettle(biller.auto_settle);
        setServiceName(updatedBillerServices);
        setServices(serviceNames);
      })
      .then((_) => setLoading(false));
  }, []);

  return (
    <React.Fragment>
      <Typography
        component={"h1"}
        sx={{ textAlign: "center", fontWeight: "bold" }}
      >
        Edit Biller
      </Typography>
      {loading ? (
        "LOADING..."
      ) : (
        <Box mt={2} component="form" onSubmit={updateBiller}>
          <FormGroup>
            <Grid item>
              <TextField
                fullWidth
                label="Biller Name"
                id="biller_name"
                name="biller_name"
                variant="outlined"
                defaultValue={biller?.biller_name}
                sx={{ mb: 1 }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label="Settlement Account"
                id="settlement_account_number"
                name="settlement_account_number"
                variant="outlined"
                defaultValue={biller?.settlement_account_number}
                sx={{ mb: 1 }}
                onChange={handleChange}
              />
            </Grid>

            <FormControl fullWidth sx={{ mb: 1 }}>
              <InputLabel id="services">Services</InputLabel>
              <Select
                labelId="services"
                id="services"
                name="services"
                multiple
                value={serviceNames}
                onChange={handleServiceChange}
                input={<OutlinedInput label="Services" />}
                renderValue={(selected) =>
                  _.toString(selected).length > 30
                    ? _.join(selected, ", ").slice(0, 33).concat("...")
                    : selected.map((x) => x).join(", ")
                }
                MenuProps={MenuProps}
              >
                {services.map((service, index) => (
                  <MenuItem key={index} value={service}>
                    <Checkbox
                      checked={
                        serviceNames.findIndex((item) => item === service) >= 0
                      }
                    />
                    <ListItemText primary={service} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Grid item>
              Active
              <Button
                onClick={toggleActive}
                variant="outlined"
                size="small"
                color={active ? toggleIcons.on.color : toggleIcons.off.color}
                sx={{ m: 1 }}
              >
                {active ? toggleIcons.on.icon : toggleIcons.off.icon}
              </Button>
              {/* </Grid>

            <Grid item> */}
              Auto Settle
              <Button
                onClick={toggleAutoSettle}
                variant="outlined"
                size="small"
                color={
                  autoSettle ? toggleIcons.on.color : toggleIcons.off.color
                }
                sx={{ m: 1 }}
              >
                {autoSettle ? toggleIcons.on.icon : toggleIcons.off.icon}
              </Button>
            </Grid>

            <Grid item sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="outlined"
                startIcon={<CheckIcon />}
                type="submit"
                color="primary"
                sx={{ m: 1 }}
              >
                Save
              </Button>

              <Button
                variant="outlined"
                startIcon={<CancelIcon />}
                color="warning"
                sx={{ m: 1 }}
                onClick={() => navigate(billersViewUrl)}
              >
                Cancel
              </Button>
            </Grid>
          </FormGroup>
        </Box>
      )}
    </React.Fragment>
  );
}
