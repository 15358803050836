import { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { AppContext } from "../helpers/contexts/AppContext";
import { getSessionData } from "../helpers/utils";

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { auth, persist, setAuth } = useContext(AppContext);

  useEffect(() => {
    const { u, aT, c, p } = getSessionData();
    // Avoids unwanted call to verifyRefreshToken
    !auth?.token && persist
      ? setAuth({
          user: u,
          permissions: p,
          access_token: aT,
          client: c,
        })
      : setIsLoading(false);

        setIsLoading(false);

    return () => {
      setIsLoading(false);
    };
  }, []);
    return (
    <>{!persist ? <Outlet /> : isLoading ? <p>loading.....</p> : <Outlet />}</>
  );
};

export default PersistLogin;
