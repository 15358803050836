import React, { useState, useEffect, useContext } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { Grid, Typography, Button } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { AppContext } from "../helpers/contexts/AppContext";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const style = {
  width: "100%",
  display: "flex",
  bgcolor: "background.paper",
  flexDirection: "column",
};

const { REACT_APP_BASE_API_URL } = process.env;
const endpoint = "module";
const redirectUrl = "/modules";

export default function Module() {
  const [module, setModule] = useState({});

  const { id } = useParams();
  const { deleteAndRedirect } = useContext(AppContext);
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  useEffect(() => {
    axios.get(`${REACT_APP_BASE_API_URL}/${endpoint}/${id}`).then((res) => {
      const { result } = res.data;
      setModule(result);
    });
  }, []);

  return (
    <Grid sx={{ display: "flex", flexDirection: "column" }}>
      <List sx={style} component="nav" aria-label="Status Details">
        <ListItem button>
          <ListItemText primary="Name" />
          <Typography>{module.module_name}</Typography>
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText primary="Description" />
          <Typography>{module.module_desc}</Typography>
        </ListItem>
        <Divider />
      </List>

      <Grid sx={{ mt: 2, justifyContent: "center" }}>
        <Button
          variant="outlined"
          sx={{ m: 0.5 }}
          aria-label="all-apps"
          color="primary"
          onClick={() => navigate("/modules")}
        >
          View All Modules
        </Button>
        <Button
          variant="outlined"
          sx={{ m: 0.5 }}
          aria-label="delete-app"
          color="warning"
          onClick={() =>
            deleteAndRedirect(
              id,
              `${REACT_APP_BASE_API_URL}/${endpoint}`,
              "Module",
              true,
              navigate,
              redirectUrl
            )
          }
        >
          Delete This Module
        </Button>
      </Grid>
    </Grid>
  );
}
