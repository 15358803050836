import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import _ from "lodash";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { enqueueSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const { REACT_APP_BASE_API_URL } = process.env;
const endpoint = "role";

export default function AddRole() {
  const [role, setRole] = React.useState({});
  const [roleName, setRoleName] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [permissions, setPermissions] = React.useState([]);
  const [permissionName, setPermissionName] = React.useState([]);
  const axios = useAxiosPrivate();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // Get permission
  const getPrePopulatedFields = () => {
    const permissionsUrl = "module/all";

    let permissionNames = [];
    axios.get(`${REACT_APP_BASE_API_URL}/${permissionsUrl}`).then((res) => {
      const { result } = res.data;
      result.map((app) => {
        app.permissions.map((permission) =>
          permissionNames.push(permission.perm)
        );
      });
    });

    setPermissions(permissionNames);
  };

  const handlePermissionChange = (event) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved = [];

    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o === item) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x === item);
      } else {
        duplicateRemoved.push(item);
      }
    });
    setPermissionName(duplicateRemoved);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event) => {
    const { value } = event.target;
    setRoleName(value);
  };

  const add = (e) => {
    const data = new FormData(e.currentTarget);
    e.preventDefault();

    const permissions = data.get("permissions");

    !permissions || (!roleName && alert("Please fill all fields"));

    let newRole = {};

    newRole.permissions = permissions;
    newRole.role_name = roleName;
    setRole(newRole);

    console.log(newRole);
    setOpen(false);
    axios
      .post(`${REACT_APP_BASE_API_URL}/${endpoint}`, newRole)
      .then((res) => {
        if (res.status && res.status === 200) {
          enqueueSnackbar("Role added successfully.", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  React.useEffect(() => {
    getPrePopulatedFields();
  }, []);

  return (
    <Grid
      item
      xs={12}
      lg={12}
      md={12}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      pb={1}
    >
      <Paper>
        <div>
          <Button onClick={handleOpen}>Add New Role</Button>
          <Modal
            aria-labelledby="addRole"
            aria-describedby="Form for adding new role."
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <Typography
                  id="addUser"
                  variant="h6"
                  component="h2"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Enter Role Details
                </Typography>

                <Box
                  mt={2}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                    m: 1,
                  }}
                  component="form"
                  onSubmit={add}
                >
                  <Grid container direction={"column"} spacing={2}>
                    <Grid item>
                      <TextField
                        fullWidth
                        label="Role Name"
                        id="roleName"
                        name="roleName"
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item>
                      <FormControl fullWidth>
                        <InputLabel id="role">Permissions</InputLabel>
                        <Select
                          labelId="add-permissions"
                          id="permissions"
                          name="permissions"
                          multiple
                          value={permissionName}
                          onChange={handlePermissionChange}
                          input={<OutlinedInput label="Permissions" />}
                          renderValue={(selected) =>
                            _.toString(selected).length > 30
                              ? _.join(selected, ", ")
                                  .slice(0, 33)
                                  .concat("...")
                              : selected.map((x) => x).join(", ")
                          }
                          MenuProps={MenuProps}
                        >
                          {permissions.map((permission, index) => (
                            <MenuItem key={index} value={permission}>
                              <Checkbox
                                checked={
                                  permissionName.findIndex(
                                    (item) => item === permission
                                  ) >= 0
                                }
                              />
                              <ListItemText primary={permission} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        type="submit"
                        color="primary"
                      >
                        Add Role
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Fade>
          </Modal>
        </div>
      </Paper>
    </Grid>
  );
}
