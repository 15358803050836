import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import { Button, Grid } from "@mui/material";
import { AppContext } from "../helpers/contexts/AppContext";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { CSVLink } from "react-csv";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CancelIcon from "@mui/icons-material/Cancel";
import Box from "@mui/material/Box";
import { enqueueSnackbar } from "notistack";

import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AccountTransactions() {
  const endpoint = "account-transaction/by-account";
  const limit = 100;
  const { REACT_APP_ACCOUNTS_API_URL } = process.env;
  const { accountNumber } = useParams();
  const axios = useAxiosPrivate();
  const navigate = useNavigate();
  // const accountNum = "123456789";

  const [loading, setLoading] = React.useState(false);
  const [transactions, setTransactions] = React.useState([]);
  const { CustomToolbar, auth } = React.useContext(AppContext);

  const [viewTrans, setViewTrans] = React.useState({});
  const [shouldRedirect, setShouldRedirect] = React.useState(false);
  const [row, setRow] = React.useState({});

  // For reversal modal
  const [narration, setNarration] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const reversalEndpoints = {
    deposit: "transaction/deposit/initiate-reverse",
    withdrawal: "transaction/withdrawal/initiate-reverse",
    transfer: "transaction/transfer/initiate-reverse",
    pay: "transaction/pay/initiate-reverse",
  };

  const handleReversal = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking
    handleOpen(); // open reversal modal

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

    setRow(thisRow);
    reverseTransaction(thisRow);
  };

  const handleNarrationChange = (event) => {
    const { value } = event.target;
    setNarration(value);
  };

  const handleView = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

    setViewTrans(thisRow);
    setShouldRedirect(true);
  };

  const reverseTransaction = (e) => {
    e.preventDefault();
    const { transaction_id, transactionType } = row;
    const url = reversalEndpoints[transactionType.toLowerCase()];
    const payload = {
      transaction_id,
      narration,
      reversal_status: "INITIATED",
    };

    handleClose();
    axios
      .post(`${REACT_APP_ACCOUNTS_API_URL}/${url}`, payload)
      .then((res) => {
        if (res.status && res.status === 200) {
          enqueueSnackbar("Transaction reversal initiated successfully", {
            variant: "success",
          });
          navigate("/reversal-transactions");
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  const actions = [
    {
      name: "view",
      onclick: (e, params) => handleView(e, params),
      icon: <VisibilityIcon />,
      color: "primary",
      label: "View",
      role: "VIEW_ACCOUNT_TRANSACTIONS",
    },
    {
      name: "reverse",
      onclick: (e, params) => handleReversal(e, params),
      icon: <SettingsBackupRestoreIcon />,
      color: "warning",
      label: "Reverse",
      role: "REVERSE_ACCOUNT_TRANSACTIONS",
    },
  ];

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "transaction_id",
      headerName: "Transaction ID",
      width: 150,
    },
    { field: "currency", headerName: "Currency", width: 100 },
    { field: "amount", headerName: "Amount", width: 80 },
    { field: "charge", headerName: "Charge", width: 80 },
    { field: "closing_balance", headerName: "Balance", width: 100 },
    { field: "transaction_type", headerName: "Transaction Type", width: 150 },
    { field: "created_at", headerName: "Date Created", width: 150 },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => {
        return actions.map((action) =>
          auth?.permissions?.find(
            (perm) => action.role.includes(perm) || perm === "ROOT"
          ) ? (
            <Button
              variant="outlined"
              startIcon={action.icon}
              sx={{ m: 1 }}
              onClick={(e) => action.onclick(e, params)}
              color={action.color}
              aria-label={action.label}
            >
              {action.label}
            </Button>
          ) : (
            ""
          )
        );
      },
      width: 250,
    },
  ];

  React.useEffect(() => {
    shouldRedirect &&
      navigate(`/account-transactions/transaction/${viewTrans.id}`);
    setLoading(true);

    let transList = [];
    axios
      .get(
        `${REACT_APP_ACCOUNTS_API_URL}/${endpoint}/${accountNumber}?limit=${limit}`
      )
      .then((res) => {
        const { result } = res.data;
        result.map((transaction) => {
          transaction.transaction_type = transaction.transaction_type.type;
          transList.push(transaction);
        });
        setTransactions(transList);
        setLoading(false);
      });
  }, [shouldRedirect]);

  return (
    <React.Fragment>
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        {open && (
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="addNarration"
              aria-describedby="add-narration-for-reversal"
            >
              <Box sx={modalStyle}>
                <Typography
                  id="addNarration"
                  variant="h6"
                  component="h2"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Add Narration
                </Typography>
                <Typography id="add-narration-for-reversal" sx={{ mt: 2 }}>
                  <Box
                    mt={2}
                    sx={{
                      width: 500,
                      maxWidth: "100%",
                      m: 1,
                    }}
                    component="form"
                    onSubmit={reverseTransaction}
                  >
                    <Grid container direction={"column"} spacing={2}>
                      <Grid item>
                        <TextField
                          required
                          fullWidth
                          label="Narration"
                          id="narration"
                          name="narration"
                          multiline
                          onChange={handleNarrationChange}
                        />
                      </Grid>
                      <Grid
                        item
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          variant="outlined"
                          startIcon={<CheckBoxIcon />}
                          type="submit"
                          color="primary"
                          sx={{ m: 1 }}
                        >
                          Confrim
                        </Button>

                        <Button
                          variant="outlined"
                          startIcon={<CancelIcon />}
                          color="warning"
                          sx={{ m: 1 }}
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Typography>
              </Box>
            </Modal>
          </div>
        )}
      </Box>
      <div style={{ width: "100%" }}>
        <DataGrid
          autoHeight
          rows={transactions}
          columns={columns}
          rowsPerPageOptions={[20, 50, 100]}
          getRowHeight={() => "auto"}
          disableColumnMenu
          loading={loading}
          components={{ Toolbar: CustomToolbar }}
        />
        <Grid sx={{ mt: 2, justifyContent: "center" }}>
          <Button
            variant="outlined"
            startIcon={<UploadFileIcon />}
            sx={{ m: 1 }}
            aria-label="export-transactions"
          >
            <Typography>
              <CSVLink
                data={transactions}
                style={{ textDecoration: "none" }}
                filename={`${accountNumber}-transactions_${new Date()}`}
              >
                Export
              </CSVLink>
            </Typography>
          </Button>
        </Grid>
      </div>
    </React.Fragment>
  );
}
