import { createTheme } from "@mui/material/styles";

import { AppContext } from "../helpers/contexts/AppContext";
import { Link, useParams } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { enqueueSnackbar } from "notistack";

import * as React from "react";

const theme = createTheme();

export default function VerifyEmail() {
  const { REACT_APP_BASE_API_URL } = process.env;
  const { token } = useParams();

  const [user, setUser] = React.useState({});
  const [showRedirectMsg, setShowRedirectMsg] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const axios = useAxiosPrivate();
  const { id } = useParams();

  const endpoint = "user";

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const code = data.get("verification_code");

    if (!code) {
      enqueueSnackbar("Enter verificatiion code.", {
        variant: "error",
      });
    } else {
      enqueueSnackbar("Email verification successful.", {
        variant: "success",
      });

      setShowRedirectMsg(true);
    }
  };

  React.useEffect(() => {
    setLoading(true);

    axios
      .get(`${REACT_APP_BASE_API_URL}/${endpoint}/${id}`)
      .then((res) => {
        const { result } = res.data;
        setUser(result);
      })
      .then((_) => setLoading(false));
  }, []);

  return (
    <React.Fragment>
      {loading ? (
        "LOADING..."
      ) : showRedirectMsg ? (
        <p>
          Click&nbsp;
          <Link style={{ textDecoration: "none", color: "blue" }} to="/home">
            here
          </Link>
          &nbsp;to return to the homepage
        </p>
      ) : (
        <p>
          Error verifying email, try again later. Click&nbsp;
          <Link style={{ textDecoration: "none", color: "blue" }} to="/home">
            here
          </Link>
          &nbsp;to return to the homepage
        </p>
      )}
    </React.Fragment>
  );
}
