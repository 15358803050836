import axios from "../api/axios";

import useAuth from "./useAuth";

const useLogout = () => {
  const { setAuth, clearSD } = useAuth();
  const logout = async () => {
    setAuth({});
    clearSD();
    try {
      await axios.post("pucss/tokens/logout", {
        withCredentials: true,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return logout;
};

export default useLogout;
