import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LockResetIcon from "@mui/icons-material/LockReset";
import VerifiedIcon from "@mui/icons-material/Verified";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../container/ConfirmDialog/ConfirmDialog";
import { AppContext } from "../helpers/contexts/AppContext";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { enqueueSnackbar } from "notistack";

export default function Profiles() {
  const { REACT_APP_BASE_API_URL } = process.env;
  const endpoint = "profile";
  const viewUrl = "profiles";
  const axiosPrivate = useAxiosPrivate();

  const [profiles, setProfiles] = React.useState([]);

  const {
    openConfirmDialog,
    handleOpenConfirmDialog,
    handleCloseConfirmDialog,
    CustomToolbar,
    toggleBoolField,
    viewFromTable,
    auth,
  } = React.useContext(AppContext);

  // auth?.permissions?.find(perm => allowedPermissions?.includes(perm))

  const [confirmMessage, setConfirmMessage] = React.useState("");
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [action, setAction] = React.useState(null);
  const [actionPresent, setactionPresent] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [payload, setPayload] = React.useState(null);

  const navigate = useNavigate();

  const deleteProfile = (id) => {
    axiosPrivate
      .delete(`${REACT_APP_BASE_API_URL}/${endpoint}/${id}`)
      .then((res) => {
        if (res.status && res.status === 200) {
          enqueueSnackbar(`Profile deleted successfully.`, {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  const handleEdit = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

    navigate(`/profiles/${thisRow.id}/edit`);
  };

  const handleDelete = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
    setConfirmMessage(
      `Are you sure you want to delete ${thisRow.firstName} ${thisRow.lastName}'s profile ?`
    );
    setDialogHeader("Delete Profile");
    setactionPresent(true);
    setAction("delete");
    setPayload(thisRow.id);
    handleOpenConfirmDialog();
  };

  const toggleVerify = (id, state) => {
    axiosPrivate
      .patch(`${REACT_APP_BASE_API_URL}/${endpoint}/${id}/verify`, {
        verified: state,
      })
      .then((res) => {
        let updatedProfile = profiles.filter((p) => p.id === id)[0];
        let updatedProfiles = profiles.map((p) => {
          if (p.id === id) {
            return updatedProfile;
          }
          return p;
        });

        if (res.status && res.status === 200) {
          updatedProfile.verified = state;
          enqueueSnackbar(`Profile verified successfully.`, {
            variant: "success",
          });
          return setProfiles(updatedProfiles);
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  const handleToggleVerify = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

    toggleVerify(thisRow.id, !thisRow.active);
  };

  const handlePinReset = (e, params) => {
    e.stopPropagation();

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

    axiosPrivate
      .patch(`${REACT_APP_BASE_API_URL}/${endpoint}/${thisRow.id}/reset-pin`)
      .then((res) => {
        res.status &&
          res.status === 200 &&
          enqueueSnackbar(`PIN reset initiated.`, {
            variant: "success",
          });
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  const actions = [
    {
      name: "view",
      onclick: (e, params) => viewFromTable(e, params, navigate, viewUrl),
      icon: <VisibilityIcon />,
      color: "primary",
      role: ["READ_PROFILE"],
    },
    {
      name: "edit",
      onclick: (e, params) => handleEdit(e, params),
      icon: <EditIcon />,
      color: "primary",
      role: ["EDIT_PROFILE"],
    },

    {
      name: "verify",
      onclick: (e, params) => handleToggleVerify(e, params),
      icon: <VerifiedIcon />,
      color: {
        on: "success",
        off: "primary",
      },
      role: ["VERIFY_PROFILE"],
    },

    {
      name: "reset pin",
      onclick: (e, params) => handlePinReset(e, params),
      icon: <LockResetIcon />,
      color: "primary",
      role: ["RESET_PIN_PROFILE"],
    },
    {
      name: "delete",
      onclick: (e, params) => handleDelete(e, params),
      icon: <DeleteIcon />,
      color: "warning",
      role: ["DELETE_PROFILE"],
    },
  ];

  React.useEffect(() => {
    setLoading(true);
    axiosPrivate
      .get(`${REACT_APP_BASE_API_URL}/${endpoint}/all`)
      .then((res) => {
        const { result } = res.data;
        //console.log(result);
        let updatedProfiles = [];
        result.map((profile) => {
          profile.user = profile.user.username;
          updatedProfiles.push(profile);
          process.env.REACT_APP_DEBUG_MODE === "true" && console.log(profile);
        });
        setProfiles(result);
      })
      .then((_) => setLoading(false));
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "firstName", headerName: "Firstname", width: 150 },
    { field: "lastName", headerName: "Lastname", width: 150 },
    {
      field: "user",
      headerName: "Owner",
      width: 150,
    },
    { field: "verified", headerName: "Verified", width: 150 },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        return actions.map((action) =>
          auth?.permissions?.find(
            (perm) => action.role.includes(perm) || perm === "ROOT"
          ) ? (
            <Button
              disabled={
                action.name === "verify"
                  ? toggleBoolField(params, "verified")
                  : false
              }
              onClick={(e) => action.onclick(e, params)}
              variant="outlined"
              size="small"
              color={
                action.name === "verify"
                  ? toggleBoolField(params, "verified")
                    ? action.color.on
                    : action.color.off
                  : action.color
              }
              sx={{ m: 1 }}
            >
              <Tooltip
                title={
                  action.name === "verify"
                    ? toggleBoolField(params, "verified")
                      ? "Account is already verified"
                      : action.name
                    : action.name
                }
              >
                {action.icon}
              </Tooltip>
            </Button>
          ) : (
            ""
          )
        );
      },
      width: 150,
    },
  ];

  return (
    <React.Fragment>
      {openConfirmDialog && (
        <ConfirmDialog
          openConfirmDialog={openConfirmDialog}
          handleOpenConfirmDialog={() => handleOpenConfirmDialog()}
          handleCloseConfirmDialog={handleCloseConfirmDialog}
          dialogHeader={dialogHeader}
          message={confirmMessage}
          actionPresent={actionPresent}
          action={action}
          handler={deleteProfile}
          payload={payload}
        />
      )}
      <Button
        sx={{ mb: 1 }}
        size="small"
        onClick={() => navigate("/profiles/add")}
      >
        Add New Profile
      </Button>
      <div style={{ width: "100%" }}>
        <DataGrid
          autoHeight
          rows={profiles}
          columns={columns}
          rowsPerPageOptions={[20, 50, 100]}
          getRowHeight={() => "auto"}
          disableColumnMenu
          sx={{ m: 1 }}
          loading={loading}
          components={{ Toolbar: CustomToolbar }}
        />
      </div>
    </React.Fragment>
  );
}
