import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Tooltip } from "@mui/material";
import ConfirmDialog from "../container/ConfirmDialog/ConfirmDialog";
import { AppContext } from "../helpers/contexts/AppContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddPinStatus from "./AddPinStatus";
import Edit from "../container/Edit";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { enqueueSnackbar } from "notistack";

export default function PinStatuses() {
  const endpoint = "pinstatus";
  const axiosPrivate = useAxiosPrivate();
  const pinStatusViewUrl = "pinstatuses";
  const { REACT_APP_BASE_URL, REACT_APP_BASE_API_URL } = process.env;
  const url = `${REACT_APP_BASE_URL}${REACT_APP_BASE_API_URL}`;
  const nonUpdatableFields = ["id"];

  const [pinstatuses, setPinStatuses] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const skipStart = 0;
  const skipEnd = -1;
  const displayStart = 1;
  const displayEnd = -1;

  const navigate = useNavigate();

  const {
    openConfirmDialog,
    handleOpenConfirmDialog,
    handleCloseConfirmDialog,
    CustomToolbar,
    openEditDialog,
    handleCloseEditDialog,
    handleOpenEditDialog,
    edit,
  } = React.useContext(AppContext);

  const [confirmMessage, setConfirmMessage] = React.useState("");
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [action, setAction] = React.useState(null);
  const [actionPresent, setactionPresent] = React.useState(false);
  const [payload, setPayload] = React.useState(null);
  const [editDialogHeader, setEditDialogHeader] = React.useState("");
  const [editRow, setEditRow] = React.useState({});
  const [editActionPresent, setEditActionPresent] = React.useState(false);
  const [param, setParam] = React.useState(null);

  const deletePinStatus = (id) => {
    axiosPrivate
      .delete(`${REACT_APP_BASE_API_URL}/${endpoint}/${id}`)
      .then((res) => {
        if (res.status && res.status === 200) {
          setPinStatuses(pinstatuses.filter((s) => s.id !== id));
          enqueueSnackbar(`Pin Status deleted successfully.`, {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  const handleEdit = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
    setEditRow(thisRow);
    setEditDialogHeader("Update Pin Status Details");
    setEditActionPresent(true);
    setAction("edit");
    setParam(thisRow.id);
    handleOpenEditDialog();
  };

  const handleView = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
    return navigate(`/${pinStatusViewUrl}/${thisRow.id}`);
  };

  const handleDelete = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
    setConfirmMessage(
      `Are you sure you want to delete Pin Status ${thisRow.status}?`
    );
    setDialogHeader("Delete Pin Status");
    setactionPresent(true);
    setAction("delete");
    setPayload(thisRow.id);
    handleOpenConfirmDialog();
  };

  const actions = [
    {
      name: "view",
      onclick: (e, params) => handleView(e, params),
      icon: <VisibilityIcon />,
      color: "primary",
    },
    {
      name: "edit",
      onclick: (e, params) => handleEdit(e, params),
      icon: <EditIcon />,
      color: "primary",
    },
    {
      name: "delete",
      onclick: (e, params) => handleDelete(e, params),
      icon: <DeleteIcon />,
      color: "warning",
    },
  ];

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "allowed_retries", headerName: "Allowed Max Retries", width: 200 },
    { field: "description", headerName: "Description", width: 450 },
    { field: "status", headerName: "Status", width: 150 },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => {
        return actions.map((action) => (
          <Tooltip title={action.name}>
            <Button
              variant="outlined"
              startIcon={action.icon}
              sx={{ m: 1 }}
              onClick={(e) => action.onclick(e, params)}
              color={action.color}
              aria-label={action.label}
            >
              {action.label}
            </Button>
          </Tooltip>
        ));
      },
      width: 250,
    },
  ];

  React.useEffect(() => {
    setLoading(true);
    let pinstatuses = [];
    axiosPrivate
      .get(`${REACT_APP_BASE_API_URL}/${endpoint}/all`)
      .then((res) => {
        const { result } = res.data;
        result.map((row) => {
          pinstatuses.push(row);
        });
        setPinStatuses(pinstatuses);
      })
      .then((_) => setLoading(false));
  }, []);

  return (
    <React.Fragment>
      {openConfirmDialog && (
        <ConfirmDialog
          openConfirmDialog={openConfirmDialog}
          handleOpenConfirmDialog={() => handleOpenConfirmDialog()}
          handleCloseConfirmDialog={handleCloseConfirmDialog}
          dialogHeader={dialogHeader}
          message={confirmMessage}
          actionPresent={actionPresent}
          action={action}
          handler={deletePinStatus}
          payload={payload}
        />
      )}

      {openEditDialog && (
        <Edit
          openEditDialog={openEditDialog}
          handleOpenEditDialog={handleOpenEditDialog}
          handleCloseEditDialog={handleCloseEditDialog}
          dialogHeader={editDialogHeader}
          actionPresent={editActionPresent}
          action={action}
          handler={edit}
          param={param}
          row={editRow}
          skipStart={skipStart}
          skipEnd={skipEnd}
          displayStart={displayStart}
          displayEnd={displayEnd}
          url={url}
          endpoint={endpoint}
          collection={pinstatuses}
          entity="PIN status"
          setter={setPinStatuses}
          nonUpdatableFields={nonUpdatableFields}
        />
      )}
      <AddPinStatus />
      <div style={{ width: "100%" }}>
        <DataGrid
          autoHeight
          rows={pinstatuses}
          columns={columns}
          rowsPerPageOptions={[20, 50, 100]}
          getRowHeight={() => "auto"}
          disableColumnMenu
          sx={{ m: 1 }}
          loading={loading}
          components={{ Toolbar: CustomToolbar }}
        />
      </div>
    </React.Fragment>
  );
}
