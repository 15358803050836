import React from "react";
import { ReactComponent as Logo } from "./svgs/transaction-type.svg";
import { SvgIcon } from "@mui/material";

const TransactionType = () => (
  <SvgIcon>
    <Logo />
  </SvgIcon>
);

export default TransactionType;
