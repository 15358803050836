import {
  Button,
  Chip,
  Grid,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import _ from "lodash";
import * as React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

const style = {
  width: "100%",
  display: "flex",
  bgcolor: "background.paper",
  flexDirection: "column",
};

const { REACT_APP_BASE_API_URL } = process.env;
const endpoint = "user";
const profileEndpoint = "profile";

export default function User() {
  const [user, setUser] = React.useState({});
  const [userRoles, setUserRoles] = React.useState([]);
  const [userPermissions, setUserPermissions] = React.useState([]);
  const [profile, setProfile] = React.useState({});
  const axios = useAxiosPrivate();
  const navigate = useNavigate();

  const { id } = useParams();

  const noPermissionsText = "No permissions added.";
  const noRolesText = "No roles added.";

  React.useEffect(() => {
    axios
      .get(`${REACT_APP_BASE_API_URL}/${endpoint}/${id}`)
      .then((res) => {
        const { result } = res.data;
        let userPerms = [];
        let roles = [];
        _.map(result.permissions, (p) => {
          userPerms.push(p.perm);
        });
        _.map(result.roles, (r) => {
          roles.push(r.role_name);
        });
        setUser(result);
        setUserPermissions(userPerms);
        setUserRoles(roles);

        return result.profile_id;
      })
      .then((profileId) => {
        if (!_.isEmpty(profileId)) {
          axios
            .get(`${REACT_APP_BASE_API_URL}/${profileEndpoint}/${profileId}`)
            .then((res) => setProfile(res.data.result));
        }
      });
  }, []);

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        onClick={() => navigate(`/users/${user.id}/edit`)}
      >
        Edit This User
      </Button>
      <Grid sx={{ display: "flex", flexDirection: "column" }}>
        <List sx={style} component="nav" aria-label="User Details">
          <ListItem button>
            <ListItemText primary="Username" />
            <Typography>{user.username}</Typography>
          </ListItem>
          <Divider />
          <ListItem button divider>
            <ListItemText primary="Client" />
            <Typography>{user.client}</Typography>
          </ListItem>
          <ListItem button>
            <ListItemText primary="Email" />
            <Typography>{user.email}</Typography>
          </ListItem>

          <Divider />
          {!_.isEmpty(profile) ? (
            <ListItem button>
              <ListItemText primary="Profile" />
              <Link
                to={`/profiles/${profile.id}`}
                style={{ textDecoration: "none" }}
              >
                <Typography>{`${profile.firstName} ${profile.lastName}`}</Typography>
              </Link>
            </ListItem>
          ) : (
            ""
          )}

          <Divider />
        </List>

        <Grid container sx={{ flexDirection: "column" }}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid ml={1} mt={1}>
          <Grid container spacing={1}>
            <Grid lg={6} md={6} sm={12}>
              <Stack spacing={1}>
                <Paper
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    listStyle: "none",
                    p: 0.5,
                    m: 1,
                  }}
                  component="ul"
                >
                  <Typography textAlign={"center"} m={1}>
                    Permissions
                  </Typography>
                  <Grid container spacing={1}>
                    {userPermissions.length > 0 ? (
                      userPermissions.map((p, i) => (
                        <Grid item xs={4} lg={3} md={4} sm={6}>
                          <ListItem key={i}>
                            <Tooltip title={p}>
                              <Chip label={p} />
                            </Tooltip>
                          </ListItem>
                        </Grid>
                      ))
                    ) : (
                      <Typography>
                        <br />
                        <Typography
                          sx={{
                            fontStyle: "italic",
                            fontWeight: 100,
                            textAlign: "left",
                            m: 1,
                          }}
                        >
                          {noPermissionsText}
                        </Typography>
                      </Typography>
                    )}
                  </Grid>
                </Paper>
              </Stack>
            </Grid>
            <Grid lg={6} md={6} sm={12}>
              <Stack spacing={1}>
                <Paper
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    listStyle: "none",
                    p: 0.5,
                    m: 1,
                  }}
                  component="ul"
                >
                  <Typography textAlign={"center"} m={1}>
                    Roles
                  </Typography>
                  <Grid container spacing={2}>
                    {userRoles.length > 0 ? (
                      userRoles.map((p, i) => (
                        <Grid item xs={4} lg={3} md={4} sm={6}>
                          <ListItem key={i}>
                            <Chip label={p} />
                          </ListItem>
                        </Grid>
                      ))
                    ) : (
                      <Grid item xs={4} lg={3} md={4} sm={6}>
                        <Typography
                          textAlign={"center"}
                          sx={{
                            fontStyle: "italic",
                            fontWeight: 100,
                          }}
                          component="p"
                        >
                          <br />
                          {noRolesText}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
