import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LockResetIcon from "@mui/icons-material/LockReset";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../container/ConfirmDialog/ConfirmDialog";
import { AppContext } from "../helpers/contexts/AppContext";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { enqueueSnackbar } from "notistack";

export default function Users() {
  const { REACT_APP_BASE_API_URL } = process.env;

  const endpoint = "user";
  const userViewUrl = "users";

  const [users, setUsers] = React.useState([]);
  const {
    openConfirmDialog,
    handleOpenConfirmDialog,
    handleCloseConfirmDialog,
    CustomToolbar,
    toggleBoolField,
    auth,
  } = React.useContext(AppContext);
  const axios = useAxiosPrivate();

  const navigate = useNavigate();

  const [confirmMessage, setConfirmMessage] = React.useState("");
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [action, setAction] = React.useState(null);
  const [actionPresent, setactionPresent] = React.useState(false);
  const [payload, setPayload] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const deleteUser = (id) => {
    axios
      .delete(`${REACT_APP_BASE_API_URL}/${endpoint}/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setUsers(users.filter((user) => user.id !== id));
          enqueueSnackbar("User deleted successfully", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };
  const handleView = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
    return navigate(`/${userViewUrl}/${thisRow.id}`);
  };

  const handleEdit = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

    navigate(`/users/${thisRow.id}/edit`);
  };

  const handleDelete = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

    setConfirmMessage(
      `Are you sure you want to delete user ${thisRow.username}?`
    );
    setDialogHeader("Delete User");
    setactionPresent(true);
    setAction(deleteUser);
    setPayload(thisRow.id);
    handleOpenConfirmDialog();
  };

  const handlePassowrdReset = (e, params) => {
    e.stopPropagation();

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

    axios
      .post(
        `${REACT_APP_BASE_API_URL}/${endpoint}/${thisRow.id}/reset-password`
      )
      .then((res) => {
        if (res.status && res.status === 200) {
          enqueueSnackbar(`Password reset initiated.`, {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  const toggleVerify = (id, state) => {
    axios
      .patch(`${REACT_APP_BASE_API_URL}/${endpoint}/${id}`, {
        verified: state,
      })
      .then((res) => {
        let updatedUser = users.filter((u) => u.id === id)[0];
        let updatedUsers = users.map((u) => {
          if (u.id === id) {
            return updatedUser;
          }
          return u;
        });

        if (res.status && res.status === 200) {
          updatedUser.verified = state;
          setUsers(updatedUsers);
          enqueueSnackbar(`User verified successfully.`, {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  const handleToggleVerify = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

    toggleVerify(thisRow.id, !thisRow.active);
  };

  const actions = [
    {
      name: "view",
      onclick: (e, params) => handleView(e, params),
      icon: <VisibilityIcon />,
      color: "primary",
      role: "VIEW_USER",
    },
    {
      name: "verify",
      onclick: (e, params) => handleToggleVerify(e, params),
      icon: {
        on: <ToggleOnIcon />,
        off: <ToggleOffIcon />,
      },
      color: {
        on: "success",
        off: "warning",
      },
      role: "VERIFY_USER",
    },
    {
      name: "reset password",
      onclick: (e, params) => handlePassowrdReset(e, params),
      icon: <LockResetIcon />,
      color: "primary",
      role: "RESET_USER_PASSWORD",
    },
    {
      name: "edit",
      onclick: (e, params) => handleEdit(e, params),
      icon: <EditIcon />,
      color: "primary",
      role: "EDIT_USER",
    },
    {
      name: "delete",
      onclick: (e, params) => handleDelete(e, params),
      icon: <DeleteIcon />,
      color: "warning",
      role: "DELETE_USER",
    },
  ];

  React.useEffect(() => {
    setLoading(true);
    axios
      .get(`${REACT_APP_BASE_API_URL}/${endpoint}/all`)
      .then((res) => {
        const { result } = res.data;
        let updatedUsers = [];
        console.table(result);
        result.map((user) => {
          user.permissions = Object.keys(user.permissions).length;
          updatedUsers.push(user);
        });
        setUsers(updatedUsers);
      })
      .then((_) => setLoading(false))
      .catch(
        (err) => enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`),
        {
          variant: "error",
        }
      );
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "username", headerName: "Username", width: 150 },
    { field: "client", headerName: "Client", width: 150 },
    { field: "email", headerName: "Email", width: 150 },
    { field: "can_access_ui", headerName: "Ui Access", width: 100 },
    {
      field: "verified",
      headerName: "Verified",
      width: 70,
    },

    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        return actions.map((action) =>
          auth?.permissions?.find(
            (perm) => action.role.includes(perm) || perm === "ROOT"
          ) ? (
            <Button
              onClick={(e) => action.onclick(e, params)}
              variant="outlined"
              size="small"
              color={
                action.name === "verify"
                  ? toggleBoolField(params, "verified")
                    ? action.color.on
                    : action.color.off
                  : action.color
              }
              sx={{ m: 1 }}
            >
              <Tooltip title={action.name}>
                {action.name === "verify"
                  ? toggleBoolField(params, "verified")
                    ? action.icon.on
                    : action.icon.off
                  : action.icon}
              </Tooltip>
            </Button>
          ) : (
            ""
          )
        );
      },
      width: 150,
    },
  ];

  return (
    <React.Fragment>
      {openConfirmDialog && (
        <ConfirmDialog
          openConfirmDialog={openConfirmDialog}
          handleOpenConfirmDialog={handleOpenConfirmDialog}
          handleCloseConfirmDialog={handleCloseConfirmDialog}
          dialogHeader={dialogHeader}
          message={confirmMessage}
          actionPresent={actionPresent}
          action={action}
          handler={deleteUser}
          payload={payload}
        />
      )}

      {/* <AddUser /> */}
      <Button
        sx={{ mb: 1 }}
        size="small"
        onClick={() => navigate("/users/add")}
      >
        Add New User
      </Button>
      <div style={{ width: "100%" }}>
        <DataGrid
          autoHeight
          rows={users}
          columns={columns}
          rowsPerPageOptions={[20, 50, 100]}
          getRowHeight={() => "auto"}
          disableColumnMenu
          sx={{ m: 1 }}
          loading={loading}
          components={{ Toolbar: CustomToolbar }}
        />
      </div>
    </React.Fragment>
  );
}
