import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { enqueueSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const endpoint = "pinstatus";

export default function AddPinStatus() {
  const [pinstatus, setPinStatus] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = React.useState([]);
  const [allowedRetries, setAllowedRetries] = React.useState([]);
  const [description, setDescription] = React.useState([]);
  const axios = useAxiosPrivate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event) => {
    const { value, name } = event.target;
    switch (name) {
      case "status":
        setStatus(value);
        break;
      case "description":
        setDescription(value);
        break;
      case "allowedRetries":
        setAllowedRetries(value);
        break;
      default:
        enqueueSnackbar(`Unknown field : ${name}.`, {
          variant: "error",
        });
    }
  };

  const add = (e) => {
    e.preventDefault();

    if (status && description && allowedRetries) {
    } else {
      enqueueSnackbar("Please fill all fields.", {
        variant: "error",
      });
    }

    let newPinStatus = {};
    newPinStatus.status = status;
    newPinStatus.description = description;
    newPinStatus.allowed_retries = allowedRetries;

    setPinStatus(newPinStatus);

    console.log(newPinStatus);
    setOpen(false);
    axios
      .post(`${process.env.REACT_APP_BASE_API_URL}/${endpoint}`, newPinStatus)
      .then((res) => {
        if (res.status && res.status === 200) {
          enqueueSnackbar("PinStatus added successfully.", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  return (
    <Grid
      item
      xs={12}
      lg={12}
      md={12}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Paper>
        <div>
          <Button onClick={handleOpen}>Add New Pin Status</Button>
          <Modal
            aria-labelledby="addPinStatus"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <Typography
                  id="addPinStatus"
                  variant="h6"
                  component="h2"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Enter Pin Status Details
                </Typography>

                <Box
                  mt={2}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                    m: 1,
                  }}
                  component="form"
                  onSubmit={add}
                >
                  <Grid container direction={"column"} spacing={2}>
                    <Grid item>
                      <TextField
                        fullWidth
                        label="status"
                        id="status"
                        name="status"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        fullWidth
                        label="description"
                        id="description"
                        name="description"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        fullWidth
                        label="allowed pin retries"
                        id="allowed pin retries"
                        name="allowedRetries"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        type="submit"
                        color="primary"
                      >
                        Add Pin Status
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Fade>
          </Modal>
        </div>
      </Paper>
    </Grid>
  );
}
