import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import _ from "lodash";

export default function EditNotificationChannel({
  openEditDialog,
  handleCloseEditDialog,
  dialogHeader,
  actionPresent,
  handler,
  row,
  param,
  skipStart,
  skipEnd,
  displayStart,
  displayEnd,
  url,
  endpoint,
  collection,
  entity,
  setter,
  nonUpdatableFields,
}) {
  const [fields, setFields] = React.useState({});
  const [payload, setPayload] = React.useState({});
  const handleChange = (e) => {
    setFields({ ...fields, [e.target.name]: e.target.value });
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };

  React.useEffect(() => {
    Object.entries(row)
      .slice(skipStart, skipEnd)
      .map((col) => setFields({ ...fields, [col[0]]: col[1] }));
  }, []);

  return (
    <div>
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>{dialogHeader}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {Object.entries(row)
              .slice(displayStart, displayEnd)
              .map((key) => (
                <TextField
                  disabled={nonUpdatableFields.includes(key[0].toLowerCase())}
                  autoFocus
                  margin="dense"
                  id={key[0]}
                  name={key[0]}
                  label={
                    key[0].includes("_")
                      ? key[0]
                          .split("_")
                          .filter((x) => x.length > 0)
                          .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
                          .join(" ")
                      : key[0].charAt(0).toUpperCase() + key[0].slice(1)
                  }
                  type={
                    key[0].toLowerCase().includes("email") ? "email" : "text"
                  }
                  fullWidth
                  variant="outlined"
                  defaultValue={key[1]}
                  key={key[0]}
                  onChange={handleChange}
                />
              ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              handleCloseEditDialog(
                false,
                handler,
                param,
                payload,
                url,
                endpoint,
                collection,
                entity,
                setter
              )
            }
          >
            Cancel
          </Button>
          <Button
            onClick={() =>
              handleCloseEditDialog(
                actionPresent,
                handler,
                param,
                payload,
                url,
                endpoint,
                collection,
                entity,
                setter
              )
            }
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
