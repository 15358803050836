import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import Avatar from "@mui/material/Avatar";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import * as React from "react";

const theme = createTheme();
export default function VerifyEmail() {

  const [email, setEmail] = React.useState("");
  const [showRedirectMsg, setShowRedirectMsg] = React.useState(false);
  const [err, setErr] = React.useState(false);

  const endpoint = "user/reset-password";
  const { REACT_APP_BASE_URL } = process.env;

  const handleSubmit = (event) => {
    event.preventDefault();

    const pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    if (!email || !pattern.test(email)) {
      enqueueSnackbar("Enter a valid email.", {
        variant: "error",
      });
      setErr(true);
    } else {
      axios
        .post(`${REACT_APP_BASE_URL}pucss/${endpoint}`, { email })
        .then((res) => {
          console.log(res);
          if (res.status && res.status === 200) {
            enqueueSnackbar("Password reset link sent successfully.", {
              variant: "success",
              autoHideDuration: 3000,
            });
            setShowRedirectMsg(true);
          }
        })
        .catch((err) => {
          return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
            variant: "error",
            autoHideDuration: 3000,
          });
        });
    }
  };

  return (
    <React.Fragment>
      {showRedirectMsg ? (
        <p>
          Password reset link has been sent to {email}. Click&nbsp;
          <Link style={{ textDecoration: "none", color: "blue" }} to="/login">
            here
          </Link>
          &nbsp;to return to login
        </p>
      ) : (
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <ContactMailIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Reset Password
              </Typography>
              <Typography>
                <small>Enter your email to reset your password</small>
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  error={err}
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  autoFocus
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Reset Password
                </Button>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      )}
    </React.Fragment>
  );
}
