import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import axios from "axios";
import * as React from "react";

const theme = createTheme();

export default function ResetPassword() {
  const { REACT_APP_BASE_URL } = process.env;
  const endpoint = "user/change-password";

  const [showRedirectMsg, setShowRedirectMsg] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [err, setErr] = React.useState(false);
  const { uvt } = useParams();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (password !== confirmPassword || !password || !confirmPassword) {
      setErr(true);
      return enqueueSnackbar("Password do not match.", {
        variant: "error",
      });
    }
    axios
      .post(`${REACT_APP_BASE_URL}pucss/${endpoint}`, {
        password,
        user_verification_token: uvt,
      })
      .then((res) => {
        res.status &&
          res.status === 200 &&
          enqueueSnackbar("Password reset successful.", {
            variant: "success",
          });
        setShowRedirectMsg(true);
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  return (
    <React.Fragment>
      {showRedirectMsg ? (
        <p>
          Click{" "}
          <Link style={{ textDecoration: "none", color: "blue" }} to="/login">
            here
          </Link>{" "}
          to login
        </p>
      ) : (
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Reset Password
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  error={err}
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="New Password"
                  name="password"
                  autoComplete="password"
                  type="password"
                  autoFocus
                  onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                  error={err}
                  margin="normal"
                  required
                  fullWidth
                  name="confirm_password"
                  label="Confirm Password"
                  type="password"
                  id="consfirm_password"
                  autoComplete="password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Reset Password
                </Button>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      )}
    </React.Fragment>
  );
}
