import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  FormGroup,
} from "@mui/material";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import { enqueueSnackbar } from "notistack";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const { REACT_APP_ACCOUNTS_API_URL, REACT_APP_BASE_API_URL } = process.env;

export default function EditTransactionType() {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState({});
  const [accStateName, setAccStateName] = useState([]);
  const [accStates, setAccStates] = useState([]);
  const [pinStatusName, setPinStatusName] = useState([]);
  const [pinStatuses, setPinStatuses] = useState([]);
  const [serviceUrl, setServiceUrl] = useState("");
  const [shortForm, setShortForm] = useState("");
  const [charge, setCharge] = useState(null);
  const [chargeType, setChargeType] = useState("");
  const [reversible, setReversible] = useState(false);
  const [isBillerService, setIsBillerService] = useState(false);
  const [instruction, setInstruction] = useState("");

  const axios = useAxiosPrivate();
  const navigate = useNavigate();
  const { id } = useParams();
  const instructions = ["DEBIT", "CREDIT", "TRANSFER"];
  const chargeTypes = ["ABSOLUTE", "PERCENTAGE"];

  const endpoint = "transaction-type";
  const accStateUrl = "account-state/all";
  const pinStatusUrl = "pinstatus/all";

  const handleMulValChange = (event) => {
    const {
      target: { name, value },
    } = event;

    let duplicateRemoved = [];

    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o === item) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x === item);
      } else {
        duplicateRemoved.push(item);
      }
    });
    name === "allowed_account_states" && setAccStateName(duplicateRemoved);
    name === "allowed_pin_statuses" && setPinStatusName(duplicateRemoved);
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    switch (name) {
      case "biller_service":
        setIsBillerService(value);
        break;
      case "service_url":
        setServiceUrl(value);
        break;
      case "charge":
        setCharge(value);
        break;
      case "charge_type":
        setChargeType(value);
        break;
      case "type":
        setType(value);
        break;
      case "reversible":
        setReversible(value);
        break;
      case "instruction":
        setInstruction(value);
        break;
      case "short_form":
        setShortForm(value);
        break;
      default:
        alert(`Unknown field : ${name}`);
    }
  };

  const update = (e) => {
    e.preventDefault();

    axios
      .patch(`${REACT_APP_ACCOUNTS_API_URL}/${endpoint}/${id}`, {
        biller_service: isBillerService,
        service_url: serviceUrl,
        charge,
        charge_type: chargeType,

        type,
        reversible,
        instruction,
        short_form: shortForm,
      })
      .then((res) => {
        if (res.status === 200 || res.status === 206) {
          enqueueSnackbar("Transaction type updated successfully", {
            variant: "success",
          });
          navigate(`/transaction-types/${id}`);
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  React.useEffect(() => {
    setLoading(true);

    setLoading(true);
    let accStateNames = [];
    let pinStatusNames = [];

    axios.get(`${REACT_APP_ACCOUNTS_API_URL}/${endpoint}/${id}`).then((res) => {
      const { result } = res.data;
      setCharge(result.charge);
      setChargeType(result.charge_type);
      setServiceUrl(result.service_url);
      setIsBillerService(result.is_biller_service);
      setType(result.type);
      setReversible(result.reversible);
      setInstruction(result.instruction);
      setShortForm(result.short_form);

      //   TODO add acc states after they're added in resp object
      setAccStateName(
        _.map(result.allowed_account_states, (state) => {
          let names = [];
          names.push(state.status);
          return names;
        })
      );
      setPinStatusName(
        _.map(result.allowed_pin_statuses, (state) => {
          let names = [];
          names.push(state.status);
          return names;
        })
      );
      console.log(result);
    });

    axios.get(`${REACT_APP_ACCOUNTS_API_URL}/${accStateUrl}`).then((res) => {
      const { result } = res.data;
      result.map((state) => {
        accStateNames.push(state.state);
      });
    });
    axios
      .get(`${REACT_APP_BASE_API_URL}/${pinStatusUrl}`)
      .then((res) => {
        const { result } = res.data;
        result.map((status) => {
          pinStatusNames.push(status.status);
        });
      })
      .then((_) => {
        setPinStatuses(pinStatusNames);
        setAccStates(accStateNames);
      })
      .then((_) => setLoading(false));
  }, []);

  return (
    <React.Fragment>
      <Typography
        component={"h1"}
        sx={{ textAlign: "center", fontWeight: "bold" }}
      >
        Add New Transaction Type
      </Typography>
      {loading ? (
        "LOADING..."
      ) : (
        <Box mt={2} component="form" onSubmit={update}>
          <FormGroup>
            <Grid item direction={"row"}>
              <TextField
                required
                fullWidth
                label="Type"
                id="type"
                name="type"
                sx={{ mb: 1 }}
                onChange={handleChange}
                defaultValue={type}
              />
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <InputLabel id="instruction">Instruction</InputLabel>
                <Select
                  required
                  labelId="instruction"
                  id="instruction"
                  label="Instruction"
                  name="instruction"
                  sx={{ mb: 1 }}
                  onChange={handleChange}
                  defaultValue={instruction}
                >
                  {instructions.map((instruction) => (
                    <MenuItem value={instruction}>{instruction}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label="Service URL"
                id="service_url"
                name="service_url"
                defaultValue={serviceUrl}
                sx={{ mb: 1 }}
                onChange={handleChange}
              />
            </Grid>

            <Grid item>
              <FormControl required fullWidth>
                <InputLabel id="charge_type">Charge Type</InputLabel>
                <Select
                  required
                  labelId="charge_type"
                  id="charge_type"
                  label="Charge Type"
                  name="charge_type"
                  defaultValue={chargeType}
                  sx={{ mb: 1 }}
                  onChange={handleChange}
                >
                  {chargeTypes.map((type) => (
                    <MenuItem value={type}>{type}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <TextField
                required
                fullWidth
                label="Charge"
                id="charge"
                name="charge"
                defaultValue={charge}
                sx={{ mb: 1 }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item>
              <TextField
                required
                fullWidth
                label="Short Form"
                id="short_form"
                name="short_form"
                sx={{ mb: 1 }}
                onChange={handleChange}
                defaultValue={shortForm}
              />
            </Grid>
            <Grid item>
              <FormControl required fullWidth>
                <InputLabel id="allowed_pin_statuses">
                  Allowed PIN Statuses
                </InputLabel>
                <Select
                  labelId="allowed_pin_statuses"
                  id="allowed_pin_statuses"
                  name="allowed_pin_statuses"
                  multiple
                  value={pinStatusName}
                  sx={{ mb: 1 }}
                  onChange={handleMulValChange}
                  input={<OutlinedInput label="allowed_pin_statuses" />}
                  renderValue={(selected) =>
                    _.toString(selected).length > 30
                      ? _.join(selected, ", ").slice(0, 33).concat("...")
                      : selected.map((x) => x).join(", ")
                  }
                  MenuProps={MenuProps}
                >
                  {pinStatuses.map((status, index) => (
                    <MenuItem key={index} value={status}>
                      <Checkbox
                        checked={
                          pinStatusName.findIndex((item) => item === status) >=
                          0
                        }
                      />
                      <ListItemText primary={status} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl required fullWidth>
                <InputLabel id="role">Allowed Account States</InputLabel>
                <Select
                  labelId="allowed_account_states"
                  id="allowed_account_states"
                  name="allowed_account_states"
                  multiple
                  sx={{ mb: 1 }}
                  value={accStateName}
                  onChange={handleMulValChange}
                  input={<OutlinedInput label="allowed_account_states" />}
                  renderValue={(selected) =>
                    _.toString(selected).length > 30
                      ? _.join(selected, ", ").slice(0, 33).concat("...")
                      : selected.map((x) => x).join(", ")
                  }
                  MenuProps={MenuProps}
                >
                  {accStates.map((state, index) => (
                    <MenuItem key={index} value={state}>
                      <Checkbox
                        checked={
                          accStateName.findIndex((item) => item === state) >= 0
                        }
                      />
                      <ListItemText primary={state} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl>
                <FormLabel id="reversible" mr={1}>
                  Reversible?
                </FormLabel>
                <RadioGroup
                  required
                  aria-labelledby="reversible"
                  defaultValue={reversible}
                  name="reversible"
                  sx={{ mb: 1 }}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl>
                <FormLabel id="biller_service" mr={1}>
                  <Typography>Biller Service?</Typography>
                </FormLabel>
                <RadioGroup
                  required
                  aria-labelledby="biller_service"
                  defaultValue={isBillerService}
                  name="biller_service"
                  sx={{ mb: 1 }}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="outlined"
                startIcon={<CheckIcon />}
                type="submit"
                color="primary"
                sx={{ m: 1 }}
              >
                Save
              </Button>

              <Button
                variant="outlined"
                startIcon={<CancelIcon />}
                color="warning"
                sx={{ m: 1 }}
                onClick={() => navigate(`${"/transaction-types"}`)}
              >
                Cancel
              </Button>
            </Grid>
          </FormGroup>
        </Box>
      )}
    </React.Fragment>
  );
}
