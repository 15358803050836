import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AppContext } from "../helpers/contexts/AppContext";

const RequireAuth = ({ allowedPermissions }) => {
    const { auth } = useContext(AppContext);

    const location = useLocation();

    return (
        auth?.permissions?.find(perm => allowedPermissions?.includes(perm))
            ? <Outlet />
            : auth?.user
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;