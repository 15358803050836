import CancelIcon from "@mui/icons-material/Cancel";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Grid, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../helpers/contexts/AppContext";
import { createBrowserHistory } from "history";
import { enqueueSnackbar } from "notistack";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={value}
      aria-labelledby={`${value}-tab`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2, height: "100vh" }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const TransactionReversals = () => {
  // Tab value
  const [value, setValue] = useState(0);
  const [accountTransactions, setAccountTransactions] = useState([]);
  const [transferTransactions, setTransferTransactions] = useState([]);
  const [billerTransactions, setBillerTransactions] = useState([]);
  const [narration, setNarration] = React.useState("");
  const [reversalStatus, setReversalStatus] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [row, setRow] = React.useState({});
  const axios = useAxiosPrivate();
  const { REACT_APP_ACCOUNTS_API_URL } = process.env;

  const accountTransactionsEndpoint = `account-transaction/reversal-initiated?limit=10`;
  const transferTransactionsEndpoint = `transfer-transaction/reversal-initiated?limit=10`;
  const billerTransactionsEndpoint = `biller-service-transaction/reversal-initiated?limit=10`;

  const aproveEndpoints = {
    deposit: "transaction/deposit/reverse",
    withdrawal: "transaction/withdrawal/reverse",
    transfer: "transaction/transfer/reverse",
    pay: "transaction/pay/reverse",
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const history = createBrowserHistory();

  const handleNarrationChange = (event) => {
    const { value } = event.target;
    setNarration(value);
  };

  const handleAccept = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking
    handleOpen();

    const api = params.api;
    const thisRow = {};
    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

    setRow(thisRow);
    setReversalStatus("ACCEPTED");
  };

  const handleReject = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking
    handleOpen();

    const api = params.api;
    const thisRow = {};
    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

    setRow(thisRow);
    setReversalStatus("REJECTED");
  };

  const confirm = (e) => {
    e.preventDefault();

    const { transaction_id, transaction_type } = row;
    let payload = {
      transaction_id,
      reversal_status: reversalStatus,
      narration,
    };

    let url = aproveEndpoints[transaction_type.toLowerCase()];
    handleClose();
    axios
      .post(`${REACT_APP_ACCOUNTS_API_URL}/${url}`, payload)
      .then((res) => {
        if (res.status && res.status === 200) {
          enqueueSnackbar("Transaction reversal status updated successfully", {
            variant: "success",
          });

          // Refresh after accepting/rejecting reversal.
          history.go(0);
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  const { CustomExportToolbar, setExportFileName, auth } =
    useContext(AppContext);

  const today = new Date().toLocaleDateString("en-CA"); // Get date in yyyy-mm-dd format

  const actions = [
    {
      name: "accept",
      onclick: (e, params) => handleAccept(e, params),
      icon: <CheckBoxIcon />,
      color: "primary",
      role: "CANCEL_TRANSACTION_REVERSAL",
    },
    {
      name: "reject",
      onclick: (e, params) => handleReject(e, params),
      icon: <CancelIcon />,
      color: "warning",
      role: "REJECT_TRANSACTION_REVERSAL",
    },
  ];

  const billerTranscationColumns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
    },
    {
      field: "responseCode",
      headerName: "Response Code",
      type: "number",
      width: 150,
    },
    {
      field: "service",
      headerName: "Service",
      description: "Service",
      sortable: false,
      width: 150,
    },
    {
      field: "currency",
      headerName: "Currency",
      description: "currency",
      sortable: false,
      width: 150,
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => {
        return actions.map((action) =>
          auth?.permissions?.find(
            (perm) => action.role.includes(perm) || perm === "ROOT"
          ) ? (
            <Tooltip title={action.name}>
              <Button
                variant="outlined"
                startIcon={action.icon}
                sx={{ m: 1 }}
                onClick={(e) => action.onclick(e, params)}
                color={action.color}
                aria-label={action.label}
              >
                {action.label}
              </Button>
            </Tooltip>
          ) : (
            ""
          )
        );
      },
      width: 250,
    },
  ];

  const accountTransactionColumns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
    },
    {
      field: "currency",
      headerName: "Currency",
      description: "currency",
      sortable: false,
      width: 100,
    },

    {
      field: "transaction_type",
      headerName: "Transaction Type",
      width: 150,
    },
    {
      field: "starting_balance",
      headerName: "Starting Balance",
      width: 150,
    },
    {
      field: "closing_balance",
      headerName: "Closing Balance",
      width: 150,
    },
    {
      field: "transaction_id",
      headerName: "Transaction ID",
      description: "Transaction ID",
      sortable: false,
      width: 150,
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => {
        return actions.map((action) => (
          <Tooltip title={action.name}>
            <Button
              variant="outlined"
              startIcon={action.icon}
              sx={{ m: 1 }}
              onClick={(e) => action.onclick(e, params)}
              color={action.color}
              aria-label={action.label}
            >
              {action.label}
            </Button>
          </Tooltip>
        ));
      },
      width: 250,
    },
  ];

  const transferTransactionColumns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "amount",
      headerName: "Amount",
      width: 120,
    },
    {
      field: "currency",
      headerName: "Currency",
      description: "currency",
      sortable: false,
      width: 70,
    },

    {
      field: "from_account",
      headerName: "From Acc",
      width: 150,
    },
    {
      field: "to_account",
      headerName: "To Acc",
      width: 150,
    },

    {
      field: "transaction_type",
      headerName: "Trans. Type",
      width: 100,
    },

    {
      field: "narration",
      headerName: "Narration",
      width: 150,
    },

    {
      field: "reversed",
      headerName: "Reversed",
      width: 70,
    },

    {
      field: "transaction_id",
      headerName: "Transaction ID",
      description: "Transaction ID",
      sortable: false,
      width: 150,
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => {
        return actions.map((action) => (
          <Tooltip title={action.name}>
            <Button
              variant="outlined"
              startIcon={action.icon}
              sx={{ m: 1 }}
              onClick={(e) => action.onclick(e, params)}
              color={action.color}
              aria-label={action.label}
            >
              {action.label}
            </Button>
          </Tooltip>
        ));
      },
      width: 250,
    },
  ];

  const tabs = [
    "Account Transactions",
    "Transfer Transactions",
    "Biller Transactions",
  ];
  const columns = [
    accountTransactionColumns,
    transferTransactionColumns,

    billerTranscationColumns,
  ];
  const rows = [accountTransactions, transferTransactions, billerTransactions];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setExportFileName(tabs[newValue]);
  };

  const getAccountTransactions = () => {
    axios
      .get(`${REACT_APP_ACCOUNTS_API_URL}/${accountTransactionsEndpoint}`)
      .then((res) => {
        const { result } = res.data;
        let updatedTrans = [];
        result.map((transaction) => {
          transaction.transaction_type = transaction.transaction_type.type;
          updatedTrans.push(transaction);
        });
        setAccountTransactions(updatedTrans);
      });
  };

  const getTransferTransactions = () => {
    axios
      .get(`${REACT_APP_ACCOUNTS_API_URL}/${transferTransactionsEndpoint}`)
      .then((res) => {
        const { result } = res.data;
        let updatedTrans = [];
        result.map((transaction) => {
          transaction.transaction_type = transaction.transaction_type.type;
          updatedTrans.push(transaction);
        });
        setTransferTransactions(updatedTrans);
      });
  };

  const getBillerTransactions = () => {
    axios
      .get(`${REACT_APP_ACCOUNTS_API_URL}/${billerTransactionsEndpoint}`)
      .then((res) => {
        const { result } = res.data;
        let updatedTrans = [];
        result.map((transaction) => {
          transaction.transaction_type = transaction.transaction_type.type;
          updatedTrans.push(transaction);
        });
        setTransferTransactions(updatedTrans);
      });
  };

  useEffect(() => {
    setLoading(true);
    setExportFileName(tabs[0]);
    getBillerTransactions();
    getAccountTransactions();
    getTransferTransactions();
    setLoading(false);
  }, []);

  return (
    <React.Fragment>
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        {open && (
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="addNarration"
              aria-describedby="add-narration-for-reversal"
            >
              <Box sx={modalStyle}>
                <Typography
                  id="addNarration"
                  variant="h6"
                  component="h2"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Add Narration
                </Typography>
                <Typography id="add-narration-for-reversal" sx={{ mt: 2 }}>
                  <Box
                    mt={2}
                    sx={{
                      width: 500,
                      maxWidth: "100%",
                      m: 1,
                    }}
                    component="form"
                    onSubmit={confirm}
                  >
                    <Grid container direction={"column"} spacing={2}>
                      <Grid item>
                        <TextField
                          required
                          fullWidth
                          label="Narration"
                          id="narration"
                          name="narration"
                          multiline
                          onChange={handleNarrationChange}
                        />
                      </Grid>
                      <Grid
                        item
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          variant="outlined"
                          startIcon={<CheckBoxIcon />}
                          type="submit"
                          color="primary"
                          sx={{ m: 1 }}
                        >
                          Confrim
                        </Button>

                        <Button
                          variant="outlined"
                          startIcon={<CancelIcon />}
                          // type="submit"
                          color="warning"
                          sx={{ m: 1 }}
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Typography>
              </Box>
            </Modal>
          </div>
        )}
        <Tabs value={value} onChange={handleChange} centered>
          {tabs.map((tab) => (
            <Tab label={tab} />
          ))}
        </Tabs>

        {tabs.map((_, index) => (
          <TabPanel value={value} index={index}>
            <DataGrid
              sx={{ px: 1 }}
              density="comfortable"
              components={{ Toolbar: CustomExportToolbar }}
              rows={rows[index]}
              columns={columns[index]}
              pageSize={10}
              rowsPerPageOptions={[50]}
              disableSelectionOnClick
              loading={loading}
            />
          </TabPanel>
        ))}
      </Box>
    </React.Fragment>
  );
};

export default TransactionReversals;
