import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { Grid, Typography, Button, Chip, Stack, Paper } from "@mui/material";
import { useParams, useNavigate, Link } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import _ from "lodash";
import { AppContext } from "../helpers/contexts/AppContext";

const style = {
  width: "100%",
  display: "flex",
  bgcolor: "background.paper",
  flexDirection: "column",
};

const { REACT_APP_BASE_API_URL } = process.env;
const endpoint = "profile";
const redirectUrl = "/profiles";

export default function Profile() {
  const [profile, setProfile] = React.useState({});
  const navigate = useNavigate();
  const { deleteAndRedirect } = React.useContext(AppContext);
  const axios = useAxiosPrivate();
  const { id } = useParams();

  React.useEffect(() => {
    axios.get(`${REACT_APP_BASE_API_URL}/${endpoint}/${id}`).then((res) => {
      const { result } = res.data;
      result.ownerUsername = result.user.username;
      setProfile(result);
    });
  }, []);

  return (
    <Grid sx={{ display: "flex", flexDirection: "column" }}>
      <List sx={style} component="nav" aria-label="Profile Details">
        <ListItem button>
          <ListItemText primary="First Name" />
          <Typography>{profile.firstName}</Typography>
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText primary="Last Name" />
          <Typography>{profile.lastName}</Typography>
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText primary="ID Type" />
          <Typography>{profile.idType}</Typography>
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText primary="ID Number" />
          <Typography>{profile.idNumber}</Typography>
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText primary="Phone Number" />
          <Typography>{profile.phoneNumber}</Typography>
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText primary="ID Type" />
          <Typography>{profile.idType}</Typography>
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText primary="Phone Number" />
          <Typography>{profile.phoneNumber}</Typography>
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText primary="Gender" />
          <Typography>{profile.gender}</Typography>
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText primary="Date of Birth" />
          <Typography>{profile.dateOfBirth}</Typography>
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText primary="Address" />
          <Typography>{profile.address}</Typography>
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText primary="Owner Username" />
          <Typography>{profile.ownerUsername}</Typography>
        </ListItem>

        <Divider />
      </List>
      {profile.account ? (
        <>
          <ListItem button>
            <ListItemText primary="Account" />
            <Typography>
              <Link
                to={`/accounts/${profile.account.id}`}
                style={{ textDecoration: "none" }}
              >
                {profile.account.account_number}
              </Link>
            </Typography>
          </ListItem>

          <Divider />
        </>
      ) : (
        ""
      )}

      <Grid sx={{ mt: 2, justifyContent: "center" }}>
        <Button
          variant="outlined"
          sx={{ m: 0.5 }}
          aria-label="export-transactions"
          onClick={() => navigate(redirectUrl)}
        >
          View All Profiles
        </Button>
        <Button
          variant="outlined"
          sx={{ m: 0.5 }}
          aria-label="export-transactions"
          color="warning"
          onClick={() =>
            deleteAndRedirect(
              id,
              `${REACT_APP_BASE_API_URL}/${endpoint}`,
              "Profile",
              true,
              navigate,
              redirectUrl
            )
          }
        >
          Delete This Profile
        </Button>
      </Grid>
    </Grid>
  );
}
