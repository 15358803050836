import React from "react";
import { ReactComponent as Logo } from "./svgs/money-fly-icon.svg";

import { SvgIcon } from "@mui/material";

const Cashout = () => (
  <SvgIcon>
    <Logo />
  </SvgIcon>
);

export default Cashout;
