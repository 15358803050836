import React from "react";
import { ReactComponent as Logo } from "./svgs/payment-dollar-icon.svg";
import { SvgIcon } from "@mui/material";
const AccountState = () => (
  <SvgIcon>
    <Logo />
  </SvgIcon>
);

export default AccountState;
