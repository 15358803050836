import React from "react";
import { Typography } from "@mui/material";
import Link from "@mui/material/Link";

const Footer = (props) => {
  return (
    <div className="footer">
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {"Copyright © "}
        <Link
          color="inherit"
          href="https://primenetzambia.com/"
          target="_blank"
        >
          PrimeNet
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </div>
  );
};

export default Footer;
