import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Footer } from "../container";
import { useNavigate, useLocation, Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import axios from "../api/axios";
import "../App.css";
import { setSessionData } from "../helpers/utils";

const theme = createTheme();

export default function Login() {
  const { REACT_APP_BASE_API_URL } = process.env;
  const endpoint = "auth/login";

  const initialFormData = Object.freeze({
    username: "",
    password: "",
  });

  const [logginError, setLoginError] = React.useState(false);
  const [formData, updateFormData] = React.useState(initialFormData);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/home";
  const { setAuth } = useAuth();

  const handleSubmit = (event) => {
    event.preventDefault();

    const { username, password } = formData;
    axios
      .post(
        `${REACT_APP_BASE_API_URL}/${endpoint}`,

        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        },

        {
          auth: {
            username,
            password,
          },
        }
      )
      .then((res) => {
        const {
          username,
          email,
          permissions,
          access_token,
          refresh_token,
          client,
        } = res.data;

        setAuth({
          user: { email, username },
          permissions,
          access_token,
          client,
        });

        setSessionData({
          u: { email, username },
          p: permissions,
          aT: access_token,
          c: client,
          rt: refresh_token,
        });
        updateFormData(initialFormData);
      })
      .then((_) => {
        navigate(from, { replace: true });
      })
      .catch((err) => {
        setLoginError(true);
      });
  };

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  return (
    <div className="login">
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" sx={{ color: "white" }}>
              Log In
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              {logginError && (
                <Typography
                  component="p"
                  variant="p"
                  color="red"
                  fontSize="12px"
                >
                  Incorrect username or password
                </Typography>
              )}
              <TextField
                error={logginError}
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                sx={{ input: { color: "white" } }}
                InputLabelProps={{ style: { color: "white" } }}
                InputProps={{
                  style: { borderColor: "white" },
                }}
                onChange={handleChange}
              />
              <TextField
                error={logginError}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                sx={{ input: { color: "white" } }}
                InputLabelProps={{ style: { color: "white" } }}
                onChange={handleChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="success"
                sx={{ mt: 3, mb: 2, color: "white" }}
                style={{
                  borderRadius: 18,
                  backgroundColor: "darkblue",
                  padding: "18px 36px",
                  fontSize: "14px",
                }}
              >
                Log In
              </Button>
              <Link
                style={{
                  textDecoration: "none",
                  color: "white",
                  textAlign: "center",
                }}
                to="/users/reset-password/initiate"
              >
                Forgot password?
              </Link>
            </Box>
          </Box>
          <Footer sx={{ mt: 12, mb: 4 }} />
        </Container>
      </ThemeProvider>
    </div>
  );
}
