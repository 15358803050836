import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import * as React from "react";
import ConfirmDialog from "../container/ConfirmDialog/ConfirmDialog";
import Edit from "../container/Edit";
import SelectMultiple from "../container/SelectMultiple";
import { AppContext } from "../helpers/contexts/AppContext";
import AddRole from "./AddRole";
import PermissionIcon from "./icons/Permission";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

export default function Roles() {
  const endpoint = "role";
  const permsEndpoint = "module";
  const { REACT_APP_BASE_API_URL } = process.env;
  const [roles, setRoles] = React.useState([]);
  const nonUpdatableFields = ["id"];
  const axios = useAxiosPrivate();
  const skipStart = 0;
  const skipEnd = -1;
  const displayStart = 0;
  const displayEnd = -1;
  const {
    openConfirmDialog,
    handleOpenConfirmDialog,
    handleCloseConfirmDialog,
    CustomToolbar,
    handleCloseEditDialog,
    handleOpenEditDialog,
    openEditDialog,
    edit,
    auth,
  } = React.useContext(AppContext);

  const [confirmMessage, setConfirmMessage] = React.useState("");
  const [editDialogHeader, setEditDialogHeader] = React.useState("");
  const [editActionPresent, setEditActionPresent] = React.useState(false);
  const [param, setParam] = React.useState(null);
  const [editRow, setEditRow] = React.useState({});
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [action, setAction] = React.useState(null);
  const [actionPresent, setactionPresent] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [payload, setPayload] = React.useState(null);

  const [showPerms, setShowPerms] = React.useState(false);
  const [perms, setPerms] = React.useState([]);
  const navigate = useNavigate();

  const getPermissions = () => {
    axios
      .get(`${REACT_APP_BASE_API_URL}/${permsEndpoint}/all`)
      .then((res) => {
        const { result } = res.data;
        let permissions = [];
        result.map((app) => {
          app.permissions.map((permission) => permissions.push(permission));
        });
        res.status && res.status === 200 && setPerms(permissions);
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };
  const deleteRole = (id) => {
    axios
      .delete(`${REACT_APP_BASE_API_URL}/${endpoint}/${id}`)
      .then((res) => {
        if (res.status && res.status === 200) {
          setRoles(roles.filter((role) => role.id !== id));
          enqueueSnackbar(`Role deleted successfully.`, {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  const addPermissions = (id, requestBody) => {
    axios
      .patch(`${REACT_APP_BASE_API_URL}/${endpoint}/${id}`, requestBody)
      .then((res) => {
        if (res.status && res.status === 200) {
          enqueueSnackbar(`Role permissions updated.`, {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  const handleEdit = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

    navigate(`/roles/${thisRow.id}/edit`);
  };

  const handleDelete = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
    setConfirmMessage(
      `Are you sure you want to delete role ${thisRow.role_name}?`
    );
    setDialogHeader("Delete Role");
    setactionPresent(true);
    setAction(deleteRole);
    setPayload(thisRow.id);
    handleOpenConfirmDialog();
  };

  const handleAddPermission = (e, params) => {
    setShowPerms(true);
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};
    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

    addPermissions(thisRow.id);
  };

  const actions = [
    {
      name: "edit",
      onclick: (e, params) => handleEdit(e, params),
      icon: <EditIcon />,
      color: "primary",
      role: "EDIT_ROLE",
    },
    {
      name: "permissions",
      onclick: (e, params) => handleAddPermission(e, params),
      icon: <PermissionIcon />,
      color: "primary",
      role: "EDIT_ROLE_PERMISSIONS",
    },
    {
      name: "delete",
      onclick: (e, params) => handleDelete(e, params),
      icon: <DeleteIcon />,
      color: "warning",
      role: "DELETE_ROLE",
    },
  ];

  React.useEffect(() => {
    setLoading(true);
    axios.get(`${REACT_APP_BASE_API_URL}/${endpoint}/all`).then((res) => {
      const { result } = res.data;
      setRoles(result.splice([0, 2]));
    });
    getPermissions();
    setLoading(false);
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 200 },
    { field: "role_name", headerName: "Name", width: 350 },

    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        return actions.map((action) =>
          auth?.permissions?.find(
            (perm) => action.role.includes(perm) || perm === "ROOT"
          ) ? (
            <Tooltip title={action.name}>
              <Button
                onClick={(e) => action.onclick(e, params)}
                variant="outlined"
                size="small"
                color={action.color}
                sx={{ m: 1 }}
              >
                {action.icon}
              </Button>
            </Tooltip>
          ) : (
            ""
          )
        );
      },
      width: 100,
    },
  ];

  return (
    <React.Fragment>
      {openConfirmDialog && (
        <ConfirmDialog
          openConfirmDialog={openConfirmDialog}
          handleOpenConfirmDialog={() => handleOpenConfirmDialog()}
          handleCloseConfirmDialog={handleCloseConfirmDialog}
          dialogHeader={dialogHeader}
          message={confirmMessage}
          actionPresent={actionPresent}
          action={action}
          handler={deleteRole}
          payload={payload}
        />
      )}

      {showPerms && (
        <SelectMultiple
          data={perms}
          open={showPerms}
          handleClose={() => setShowPerms(false)}
        />
      )}

      {/* {openEditDialog && (
        <Edit
          openEditDialog={openEditDialog}
          handleOpenEditDialog={handleOpenEditDialog}
          handleCloseEditDialog={handleCloseEditDialog}
          dialogHeader={editDialogHeader}
          actionPresent={editActionPresent}
          action={action}
          handler={edit}
          param={param}
          row={editRow}
          skipStart={skipStart}
          skipEnd={skipEnd}
          displayStart={displayStart}
          displayEnd={displayEnd}
          url={REACT_APP_BASE_API_URL}
          endpoint={endpoint}
          collection={roles}
          entity="role"
          setter={setRoles}
          nonUpdatableFields={nonUpdatableFields}
        />
      )} */}
      <AddRole />
      <div style={{ width: "100%" }}>
        <DataGrid
          autoHeight
          rows={roles}
          columns={columns}
          rowsPerPageOptions={[20, 50, 100]}
          getRowHeight={() => "auto"}
          disableColumnMenu
          sx={{ m: 1 }}
          loading={loading}
          components={{ Toolbar: CustomToolbar }}
        />
      </div>
    </React.Fragment>
  );
}
