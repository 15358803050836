import React from "react";
import { ReactComponent as Logo } from "./svgs/perm.svg";
import { SvgIcon } from "@mui/material";
const PermissionIcon = () => (
  <SvgIcon>
    <Logo />
  </SvgIcon>
);

export default PermissionIcon;
