import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { FormGroup, Grid } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import _ from "lodash";
import { enqueueSnackbar } from "notistack";

export default function EditUser() {
  const [user, setUser] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const axios = useAxiosPrivate();
  const [roles, setRoles] = React.useState([]);
  const [permissions, setPermissions] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  const [notifications, setNotifications] = React.useState([]);
  const [roleName, setRoleName] = React.useState([]);
  const [permissionName, setPermissionName] = React.useState([]);
  const [notificationName, setNotificationName] = React.useState([]);

  // User fields
  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [client, setClient] = React.useState("");

  const endpoint = "user";
  const usersViewUrl = "/users";

  const rolesEndpoint = "role/all";
  const permissionsEndpoint = "module/all";
  const notificationsEndpoint = "notification/all";
  const clientsEndpoint = "client/all";

  const { REACT_APP_BASE_API_URL } = process.env;
  const navigate = useNavigate();
  const { id } = useParams();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const updateUser = (e) => {
    e.preventDefault();
    axios
      .patch(`${REACT_APP_BASE_API_URL}/${endpoint}/${id}`, {
        permissions: _.join(permissionName, ","),
        username,
        client,
        email,
        roles: _.join(roleName, ","),
        notifications: _.join(notificationName, ","),
      })
      .then((res) => {
        if (res.status && res.status === 200) {
          enqueueSnackbar("User details updated successfully.", {
            variant: "success",
          });
        }
      })
      .then((_) => {
        navigate(`${usersViewUrl}/${id}`);
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    switch (name) {
      case "email":
        setEmail(value);
        break;
      case "client":
        setClient(value);
        break;
      case "username":
        setUsername(value);
        break;
      default:
        enqueueSnackbar(`Unknown field : ${event.target.name}`, {
          variant: "error",
        });
    }
  };

  const handlePermissionChange = (event) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved = [];

    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o === item) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x === item);
      } else {
        duplicateRemoved.push(item);
      }
    });
    setPermissionName(duplicateRemoved);
  };

  const handleRoleChange = (event) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved = [];

    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o === item) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x === item);
      } else {
        duplicateRemoved.push(item);
      }
    });
    setRoleName(duplicateRemoved);
  };

  const handleNotificationChange = (event) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved = [];

    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o === item) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x === item);
      } else {
        duplicateRemoved.push(item);
      }
    });
    setNotificationName(duplicateRemoved);
  };

  // Get all roles, permissions and notifications.
  // Get user's current roles, permissions and notifications.

  const getPrePopulatedFields = () => {
    let roleNames = [];
    let notificationNames = [];
    let clientNames = [];
    let permissionNames = [];

    setLoading(true);

    axios.get(`${REACT_APP_BASE_API_URL}/${rolesEndpoint}`).then((res) => {
      const { result } = res.data;
      result.map((role) => {
        roleNames.push(role.role_name);
      });
    });

    axios
      .get(`${REACT_APP_BASE_API_URL}/${permissionsEndpoint}`)
      .then((res) => {
        const { result } = res.data;
        result.map((app) => {
          app.permissions.map((permission) =>
            permissionNames.push(permission.perm)
          );
        });
      });

    axios
      .get(`${REACT_APP_BASE_API_URL}/${notificationsEndpoint}`)
      .then((res) => {
        const { result } = res.data;
        result.map((notification) => {
          notificationNames.push(notification.notification_name);
        });
      });

    axios.get(`${REACT_APP_BASE_API_URL}/${clientsEndpoint}`).then((res) => {
      const { result } = res.data;
      result.map((c) => {
        clientNames.push(c.clientName);
      });
      setClients(clientNames);
    });

    axios
      .get(`${REACT_APP_BASE_API_URL}/${endpoint}/${id}`)
      .then((res) => {
        const { result } = res.data;
        setUser(result);
        setEmail(result.email);
        setUsername(result.username);
        setClient(result.client);
        return result;
      })
      .then((result) => {
        let updatedUserPerms = [];
        let updatedUserRoles = [];
        let updatedUserNotifications = [];

        result.permissions.map((p) => {
          updatedUserPerms.push(p.perm);
        });

        _.map(result.notifications, (n) => {
          updatedUserNotifications.push(n.notification_name);
        });

        _.map(result.roles, (r) => {
          updatedUserRoles.push(r.role_name);
        });

        setRoleName(updatedUserRoles);
        setNotificationName(updatedUserNotifications);
        setPermissionName(updatedUserPerms);
      })
      .then((_) => setLoading(false));

    setRoles(roleNames);
    setNotifications(notificationNames);
    setClients(clientNames);
    setPermissions(permissionNames);
  };

  React.useEffect(() => {
    getPrePopulatedFields();
  }, []);

  return (
    <React.Fragment>
      {loading ? (
        "LOADING..."
      ) : (
        <Box mt={2} component="form" onSubmit={updateUser}>
          <FormGroup>
            <TextField
              fullWidth
              required
              margin="dense"
              id="username"
              name="username"
              label="Username"
              variant="outlined"
              defaultValue={user.username}
              onChange={handleChange}
            />

            <TextField
              fullWidth
              required
              margin="dense"
              id="email"
              name="email"
              label="Email"
              variant="outlined"
              defaultValue={user.email}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              required
              margin="dense"
              id="client"
              name="client"
              label="Client"
              variant="outlined"
              defaultValue={user.client}
              sx={{ mb: 1 }}
              onChange={handleChange}
            />

            <FormControl fullWidth sx={{ mb: 1 }}>
              <InputLabel id="role">Roles</InputLabel>
              <Select
                labelId="roles"
                id="roles"
                name="roles"
                multiple
                value={roleName}
                onChange={handleRoleChange}
                input={<OutlinedInput label="Roles" />}
                renderValue={(selected) =>
                  _.toString(selected).length > 30
                    ? _.join(selected, ", ").slice(0, 33).concat("...")
                    : selected.map((x) => x).join(", ")
                }
                MenuProps={MenuProps}
              >
                {roles.map((role, index) => (
                  <MenuItem key={index} value={role}>
                    <Checkbox
                      checked={roleName.findIndex((item) => item === role) >= 0}
                    />
                    <ListItemText primary={role} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 1 }}>
              <InputLabel id="permissions">Permissions</InputLabel>
              <Select
                labelId="permissions"
                id="permissions"
                name="permissions"
                multiple
                value={permissionName}
                onChange={handlePermissionChange}
                input={<OutlinedInput label="Permissions" />}
                renderValue={(selected) =>
                  _.toString(selected).length > 30
                    ? _.join(selected, ", ").slice(0, 33).concat("...")
                    : selected.map((x) => x).join(", ")
                }
                MenuProps={MenuProps}
              >
                {permissions.map((permission, index) => (
                  <MenuItem key={index} value={permission}>
                    <Checkbox
                      checked={
                        permissionName.findIndex(
                          (item) => item === permission
                        ) >= 0
                      }
                    />
                    <ListItemText primary={permission} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* --- */}
            <FormControl fullWidth sx={{ mb: 1 }}>
              <InputLabel id="notificationChannels">Notifications</InputLabel>
              <Select
                labelId="notificationChannels"
                id="notificationChannels"
                name="notificationChannels"
                multiple
                value={notificationName}
                onChange={handleNotificationChange}
                input={<OutlinedInput label="notificationChannels" />}
                renderValue={(selected) =>
                  _.toString(selected).length > 30
                    ? _.join(selected, ", ").slice(0, 33).concat("...")
                    : selected.map((x) => x).join(", ")
                }
                MenuProps={MenuProps}
              >
                {notifications.map((notification, index) => (
                  <MenuItem key={index} value={notification}>
                    <Checkbox
                      checked={
                        notificationName.findIndex(
                          (item) => item === notification
                        ) >= 0
                      }
                    />
                    <ListItemText primary={notification} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* --- */}

            <FormControl>
              <FormLabel id="verified">Verified</FormLabel>
              <RadioGroup
                aria-labelledby="verified"
                defaultValue={user.verified}
                name="verified"
              >
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="False"
                />
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="True"
                />
              </RadioGroup>
            </FormControl>
            <Grid item sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="outlined"
                startIcon={<CheckIcon />}
                type="submit"
                color="primary"
                sx={{ m: 1 }}
              >
                Save
              </Button>

              <Button
                variant="outlined"
                startIcon={<CancelIcon />}
                color="primary"
                sx={{ m: 1 }}
                onClick={() => navigate(`${usersViewUrl}`)}
              >
                Cancel
              </Button>
            </Grid>
          </FormGroup>
        </Box>
      )}
    </React.Fragment>
  );
}
