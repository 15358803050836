import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Tooltip } from "@mui/material";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import EditNotificationTemplate from "./EditNotificationTemplate";
import ConfirmDialog from "../container/ConfirmDialog/ConfirmDialog";
import { AppContext } from "../helpers/contexts/AppContext";
import AddNotificationTemplate from "./AddNotificationTempate";
import { enqueueSnackbar } from "notistack";

export default function NotificationTemplates() {
  const endpoint = "notification-template/all";
  const [templates, setTemplates] = React.useState([]);
  const {
    openConfirmDialog,
    handleOpenConfirmDialog,
    handleCloseConfirmDialog,
    CustomToolbar,
    openEditDialog,
    handleOpenEditDialog,
    handleCloseEditDialog,
    auth,
  } = React.useContext(AppContext);

  const [editDialogHeader, setEditDialogHeader] = React.useState("");
  const [editActionPresent, setEditActionPresent] = React.useState(false);
  const [param, setParam] = React.useState(null);
  const [editRow, setEditRow] = React.useState({});
  const axios = useAxiosPrivate();
  const [confirmMessage, setConfirmMessage] = React.useState("");
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [action, setAction] = React.useState(null);
  const [actionPresent, setactionPresent] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [payload, setPayload] = React.useState(null);

  const deleteNotificationTemplate = (id) => {
    axios
      .delete(`${process.env.REACT_APP_BASE_API_URL}/${endpoint}/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setTemplates(templates.filter((t) => t.id !== id));
          enqueueSnackbar(`Notification Template deleted successfully.`, {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  const editTemplate = (id, requestBody) => {
    let updatedRequestBody = {};
    Object.keys(requestBody).map((key) => {
      if (key !== "action" || key !== "id") {
        updatedRequestBody[
          key.replace(/([A-Z])/g, ($1) => "_" + $1.toLowerCase())
        ] = requestBody[key];
      }
    });

    axios
      .patch(
        `${process.env.REACT_APP_BASE_API_URL}/${endpoint}/${id}`,
        updatedRequestBody
      )
      .then((res) => {
        let updatedTemplate = templates.filter((c) => c.id === id)[0];
        Object.keys(requestBody).map((key) => {
          updatedTemplate[key] = updatedTemplate[key];
        });
        let updatedTemplates = templates.map((t) => {
          if (t.id === id) {
            return updatedTemplate;
          }
          return t;
        });

        if (res.status && res.status === 200) {
          setTemplates(updatedTemplates);
          enqueueSnackbar(`Template updated successfully.`, {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  const handleEdit = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

    setEditRow(thisRow);
    setEditDialogHeader("Update Template Details");
    setEditActionPresent(true);
    setAction("edit");
    setParam(thisRow.id);
    handleOpenEditDialog();
  };

  const handleDelete = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
    setConfirmMessage(
      `Are you sure you want to delete Notification Template ${thisRow.id}?`
    );
    setDialogHeader("Delete Notification Template");
    setactionPresent(true);
    setAction("delete");
    setPayload(thisRow.id);
    handleOpenConfirmDialog();
  };

  const actions = [
    {
      name: "edit",
      onclick: (e, params) => handleEdit(e, params),
      icon: <EditIcon />,
      color: "primary",
      role: "EDIT_NOTIFICATION_TEMPLATE",
    },
    {
      name: "delete",
      onclick: (e, params) => handleDelete(e, params),
      icon: <DeleteIcon />,
      color: "warning",
      role: "DELETE_NOTIFICATION_TEMPLATE",
    },
  ];

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "subject", headerName: "Subject", width: 300 },
    { field: "body", headerName: "Body", width: 400 },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => {
        return actions.map((action) =>
          auth?.permissions?.find(
            (perm) => action.role.includes(perm) || perm === "ROOT"
          ) ? (
            <Tooltip title={action.name}>
              <Button
                variant="outlined"
                startIcon={action.icon}
                sx={{ m: 1 }}
                onClick={(e) => action.onclick(e, params)}
                color={action.color}
                aria-label={action.label}
              >
                {action.label}
              </Button>
            </Tooltip>
          ) : (
            ""
          )
        );
      },
      width: 250,
    },
  ];

  React.useEffect(() => {
    setLoading(true);
    let templates = [];
    axios
      .get(`${process.env.REACT_APP_BASE_API_URL}/${endpoint}`)
      .then((res) => {
        const { result } = res.data;
        result.map((row) => {
          templates.push(row);
        });
        console.table(result);
        setTemplates(templates);
      })
      .then((_) => setLoading(false));
  }, []);

  return (
    <React.Fragment>
      {openConfirmDialog && (
        <ConfirmDialog
          openConfirmDialog={openConfirmDialog}
          handleOpenConfirmDialog={() => handleOpenConfirmDialog()}
          handleCloseConfirmDialog={handleCloseConfirmDialog}
          dialogHeader={dialogHeader}
          message={confirmMessage}
          actionPresent={actionPresent}
          action={action}
          handler={deleteNotificationTemplate}
          payload={payload}
        />
      )}
      {openEditDialog && (
        <EditNotificationTemplate
          openEditDialog={openEditDialog}
          handleOpenEditDialog={handleOpenEditDialog}
          handleCloseEditDialog={handleCloseEditDialog}
          dialogHeader={editDialogHeader}
          actionPresent={editActionPresent}
          action={action}
          handler={editTemplate}
          param={param}
          row={editRow}
        />
      )}
      <AddNotificationTemplate />
      <div style={{ width: "100%" }}>
        <DataGrid
          autoHeight
          rows={templates}
          columns={columns}
          rowsPerPageOptions={[20, 50, 100]}
          getRowHeight={() => "auto"}
          disableColumnMenu
          loading={loading}
          components={{ Toolbar: CustomToolbar }}
        />
      </div>
    </React.Fragment>
  );
}
