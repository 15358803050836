import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, Grid, Tooltip, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../helpers/contexts/AppContext";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Deposit from "./Deposit";
import Transfer from "./Transfer";
import Withdrawal from "./Withdrawal";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import { enqueueSnackbar } from "notistack";

const style = {
  width: "100%",
  //   maxWidth: 360,
  display: "flex",
  bgcolor: "background.paper",
  flexDirection: "column",
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const { REACT_APP_ACCOUNTS_API_URL } = process.env;
const endpoint = "account";
const limit = 5;
const transactionsEndpoint = "account-transaction/by-account";
const transferTransactionEndpoint = "transfer-transaction/by-account";
// For reversals

const reversalEndpoints = {
  deposit: "transaction/deposit/initiate-reverse",
  withdrawal: "transaction/withdrawal/initiate-reverse",
  transfer: "transaction/transfer/initiate-reverse",
  pay: "transaction/pay/initiate-reverse",
};

export default function Account() {
  const [account, setAccount] = React.useState({});
  const [transactions, setTransactions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [viewTrans, setViewTrans] = React.useState({});
  const [shouldRedirect, setShouldRedirect] = React.useState(false);
  const [row, setRow] = React.useState({});
  const axiosPrivate = useAxiosPrivate();

  // For reversal modal
  const [narration, setNarration] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();

  const { CustomToolbar, auth } = React.useContext(AppContext);

  const { id } = useParams();

  const handleNarrationChange = (event) => {
    const { value } = event.target;
    setNarration(value);
  };

  const reverseTransaction = (e) => {
    e.preventDefault();
    const { transaction_id, transaction_type } = row;
    const url = reversalEndpoints[transaction_type.toLowerCase()];
    const data = {
      transaction_id,
      narration,
      reversal_status: "INITIATED",
    };

    handleClose();
    axiosPrivate
      .post(`${REACT_APP_ACCOUNTS_API_URL}/${url}`, data)
      .then((res) => {
        if (res.status && res.status === 200) {
          enqueueSnackbar(
            "Transaction reversal initiated successfully",
            "success"
          );
          navigate("/transaction-reversals");
        }
      })
      .catch((err) => {
        enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  const handleViewAll = (accountNum) => {
    setShouldRedirect(true);
    navigate(`/account-transactions/${accountNum}`);
  };

  const handleViewAllTransferTransactions = (accountNum) => {
    setShouldRedirect(true);
    navigate(`/transfer-transactions/${accountNum}`);
  };

  const handleViewAllBillerTransactions = (accountNum) => {
    setShouldRedirect(true);
    navigate(`/biller-transactions/${accountNum}`);
  };

  const handleView = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

    setViewTrans(thisRow);
    setShouldRedirect(true);
  };

  const handleReversal = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking
    handleOpen(); // open reversal modal

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

    setRow(thisRow);
  };

  const actions = [
    {
      label: "Deposit",
      actionHandler: <Deposit accountNum={account.account_number} />,
      role: "DEPOSIT_TO_ACCOUNT",
    },
    {
      label: "Withdrawal",
      actionHandler: <Withdrawal accountNum={account.account_number} />,
      role: "WITHDRAW_FROM_ACCOUNT",
    },
    {
      label: "Funds Transfer",
      actionHandler: <Transfer sourceAccountNum={account.account_number} />,
      role: "TRANSFER_TO_ACCOUNT",
    },
    {
      label: "Account Transactions",
      icon: <ReceiptLongIcon />,
      actionHandler: () => transactionsHandler(),
      role: "VIEW_ACCOUNT_TRANSACTIONS",
    },
  ];

  const transactionsHandler = () => {};

  const transactionActions = [
    {
      name: "view",
      onclick: (e, params) => handleView(e, params),
      icon: <VisibilityIcon />,
      color: "primary",
      label: "View",
      role: "VIEW_ACCOUNT_TRANSACTION",
    },
    {
      name: "reverse",
      onclick: (e, params) => handleReversal(e, params),
      icon: <SettingsBackupRestoreIcon />,
      color: "warning",
      label: "Reverse",
      role: "REVERSE_ACCOUNT_TRANSACTION",
    },
  ];

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "currency", headerName: "Currency", width: 100 },
    { field: "charge", headerName: "Charge", width: 100 },
    { field: "amount", headerName: "Amount", width: 80 },
    { field: "closing_balance", headerName: "Balance", width: 80 },
    {
      field: "transaction_id",
      headerName: "Transaction ID",
      width: 150,
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      width: 150,
    },
    { field: "created_at", headerName: "Date Created", width: 120 },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => {
        return transactionActions.map((action) =>
          auth?.permissions?.find(
            (perm) => action.role.includes(perm) || perm === "ROOT"
          ) ? (
            <Button
              variant="outlined"
              startIcon={action.icon}
              sx={{ m: 1 }}
              onClick={(e) => action.onclick(e, params)}
              color={action.color}
              aria-label={action.label}
            >
              {action.label}
            </Button>
          ) : (
            ""
          )
        );
      },
      width: 300,
    },
  ];

  React.useEffect(() => {
    shouldRedirect &&
      navigate(`/account-transactions/transaction/${viewTrans.id}`);
    // setLoading(true);

    setLoading(true);
    axiosPrivate
      .get(`${REACT_APP_ACCOUNTS_API_URL}/${endpoint}/${id}`)
      .then((res) => {
        const { result } = res.data;
        result.account_owner = result.account_owner.username;
        result.running_balance = result.running_balance.toFixed(2);
        setAccount(result);
        return result.account_number;
      })
      .then((accNum) => {
        axiosPrivate
          .get(
            `${REACT_APP_ACCOUNTS_API_URL}/${transactionsEndpoint}/${accNum}?limit=${limit}`
          )
          .then((res) => {
            const { result } = res.data;
            let trans = [];
            result.map((t) => {
              t.transaction_type = t.transaction_type.type;
              const date =
                new Date(t.created_at).getDate() < 10
                  ? "0" + new Date(t.created_at).getDate()
                  : new Date(t.created_at).getDate();
              const month =
                new Date(t.created_at).getMonth() + 1 < 10
                  ? "0" + (new Date(t.created_at).getMonth() + 1)
                  : new Date(t.created_at).getMonth() + 1;
              const year = new Date(t.created_at).getFullYear();
              t.created_at = `${date}-${month}-${year}`;
              trans.push(t);
            });
            setTransactions(trans);
          });
      })
      .then((_) => setLoading(false));
  }, [shouldRedirect]);

  return (
    <React.Fragment>
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        {open && (
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="addNarration"
              aria-describedby="add-narration-for-reversal"
            >
              <Box sx={modalStyle}>
                <Typography
                  id="addNarration"
                  variant="h6"
                  component="h2"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Add Narration
                </Typography>
                <Typography id="add-narration-for-reversal" sx={{ mt: 2 }}>
                  <Box
                    mt={2}
                    sx={{
                      width: 500,
                      maxWidth: "100%",
                      m: 1,
                    }}
                    component="form"
                    onSubmit={reverseTransaction}
                  >
                    <Grid container direction={"column"} spacing={2}>
                      <Grid item>
                        <TextField
                          required
                          fullWidth
                          label="Narration"
                          id="narration"
                          name="narration"
                          multiline
                          onChange={handleNarrationChange}
                        />
                      </Grid>
                      <Grid
                        item
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          variant="outlined"
                          startIcon={<CheckBoxIcon />}
                          type="submit"
                          color="primary"
                          sx={{ m: 1 }}
                        >
                          Confrim
                        </Button>

                        <Button
                          variant="outlined"
                          startIcon={<CancelIcon />}
                          // type="submit"
                          color="warning"
                          sx={{ m: 1 }}
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Typography>
              </Box>
            </Modal>
          </div>
        )}
      </Box>

      <Grid sx={{ display: "flex", flexDirection: "column" }}>
        <List sx={style} component="nav" aria-label="account details">
          <ListItem button>
            <ListItemText primary="Account Number" />
            <Typography>{account.account_number}</Typography>
          </ListItem>
          <Divider />
          <ListItem button divider>
            <ListItemText primary="Balance" />
            <Typography>{account.running_balance}</Typography>
          </ListItem>
          <ListItem button>
            <ListItemText primary="Owner" />
            <Typography>{account.account_owner}</Typography>
          </ListItem>
          <Divider />
        </List>

        <Grid
          sx={{ width: "100%", display: "flex", flexDirection: "column" }}
          spacing={2}
        >
          <Typography sx={{ m: 2 }} component="h2" fontWeight={"bold"}>
            Recent Account Transactions
          </Typography>
          <DataGrid
            autoHeight
            rows={transactions}
            columns={columns}
            pageSize={5}
            getRowHeight={() => "auto"}
            disableColumnMenu
            loading={loading}
            components={{ Toolbar: CustomToolbar }}
          />
          <Grid sx={{ mt: 2, justifyContent: "center" }}>
            <Button
              variant="outlined"
              sx={{ m: 0.5 }}
              aria-label="export-transactions"
              onClick={() => handleViewAll(account.account_number)}
            >
              View Account Transactions
            </Button>
            <Button
              variant="outlined"
              sx={{ m: 0.5 }}
              aria-label="export-transactions"
              onClick={() =>
                handleViewAllTransferTransactions(account.account_number)
              }
            >
              View Transfer Transactions
            </Button>
            <Button
              variant="outlined"
              sx={{ m: 0.5 }}
              aria-label="export-transactions"
              onClick={() =>
                handleViewAllBillerTransactions(account.account_number)
              }
            >
              View Biller Transactions
            </Button>
          </Grid>
        </Grid>

        <Grid container sx={{ flexDirection: "column" }}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid ml={1} mt={1}>
          <Grid md={6} lg={6} xs={6}>
            <Typography fontWeight={"bold"} component="h2">
              Account Actions
            </Typography>
            <Grid
              lg={4}
              md={4}
              xs={2}
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {actions.map((action) =>
                auth?.permissions?.find(
                  (perm) => action.role.includes(perm) || perm === "ROOT"
                ) ? (
                  <Tooltip title={action.label}>
                    <Typography
                      m={1}
                      sx={{
                        m: 2,
                        fontSize: "36px",
                      }}
                      aria-label={action.name}
                      key={action.name}
                    >
                      {action.actionHandler}
                    </Typography>
                  </Tooltip>
                ) : (
                  ""
                )
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
