import React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { MenuItem, Menu, Tooltip } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { AppContext } from "../../helpers/contexts/AppContext";
import { Link, useNavigate } from "react-router-dom";
import useLogout from "../../hooks/useLogout";
import { getSessionData } from "../../helpers/utils";

const NavBar = () => {
  const { open, toggleDrawer, isLoggedIn, auth } = React.useContext(AppContext);
  const navigate = useNavigate();
  const logout = useLogout();
  const sd = getSessionData();
  const { username } = sd?.u;

  const signOut = async () => {
    await logout();
    navigate("/login");
  };

  const settings = [
    { name: "Account", handler: () => navigate("/profiles") },
    { name: "Logout", handler: signOut },

    // { name: "Account" },
    // { name: "Logout" },
  ];

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const drawerWidth = 240;

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    console.log(anchorElUser);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  return (
    <Box>
      <AppBar
        position="absolute"
        open={open}
        sx={{ backgroundColor: "#090133" }}
      >
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            <Link style={{ textDecoration: "none", color: "white" }} to="/home">
              Primenet Wallet
            </Link>
          </Typography>

          {isLoggedIn && (
            <Box>
              <Tooltip title="Open settings" sx={{ color: "white" }}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <AccountCircle sx={{ color: "white" }} />
                  <Typography sx={{ color: "white" }}>{username}</Typography>
                  {!Boolean(anchorElUser) && (
                    <ArrowDropDownIcon
                      sx={{
                        display: { xs: "none", md: "flex" },
                        color: "white",
                      }}
                    />
                  )}

                  {Boolean(anchorElUser) && (
                    <ArrowDropUpIcon
                      sx={{
                        display: { xs: "none", md: "flex" },
                        color: "white",
                      }}
                    />
                  )}
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting, idx) => (
                  <MenuItem key={idx} onClick={handleOpenUserMenu}>
                    <Button
                      // to={() => setting.handler()}
                      onClick={setting.handler}
                      style={{ textDecoration: "none" }}
                    >
                      {setting.name}
                    </Button>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default NavBar;
