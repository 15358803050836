export const sections = [
  {
    p: 2,
    display: "flex",
    flexDirection: "column",
    background: "#2196f3",
  },
  {
    p: 2,
    display: "flex",
    flexDirection: "column",
    background: "#28A745",
  },
  {
    p: 2,
    display: "flex",
    flexDirection: "column",
    background: "#14A2B8",
  },
  {
    p: 2,
    display: "flex",
    flexDirection: "column",
    background: "#808080",
  },
  {
    p: 2,
    display: "flex",
    flexDirection: "column",
    background: "#DC3444",
  },
  {
    p: 2,
    display: "flex",
    flexDirection: "column",
    background: "#1a237e",
  },
];
