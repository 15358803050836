import React, { useState, useEffect, useContext } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { Grid, Typography, Button } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { AppContext } from "../helpers/contexts/AppContext";

const style = {
  width: "100%",
  display: "flex",
  bgcolor: "background.paper",
  flexDirection: "column",
};

const { REACT_APP_BASE_API_URL } = process.env;
const endpoint = "pinstatus";
const redirectUrl = "/pinstatuses";

export default function PinStatus() {
  const [pinStatus, setPinStatus] = useState({});
  const axios = useAxiosPrivate();
  const { deleteAndRedirect } = useContext(AppContext);
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    axios.get(`${REACT_APP_BASE_API_URL}/${endpoint}/${id}`).then((res) => {
      const { result } = res.data;
      setPinStatus(result);
    });
  }, []);

  return (
    <Grid sx={{ display: "flex", flexDirection: "column" }}>
      <List sx={style} component="nav" aria-label="Status Details">
        <ListItem button>
          <ListItemText primary="Status" />
          <Typography>{pinStatus.status}</Typography>
        </ListItem>
        <Divider />
        <ListItem button divider>
          <ListItemText primary="Description" />
          <Typography>{pinStatus.description}</Typography>
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText primary="Allowed Max Retries" />
          <Typography>{pinStatus.allowed_retries}</Typography>
        </ListItem>

        <Divider />
      </List>

      <Grid sx={{ mt: 2, justifyContent: "center" }}>
        <Button
          variant="outlined"
          sx={{ m: 0.5 }}
          aria-label="all-pinstatuses"
          color="primary"
          onClick={() => navigate(redirectUrl)}
        >
          View All Statuses
        </Button>
        <Button
          variant="outlined"
          sx={{ m: 0.5 }}
          aria-label="delete-pinstatus"
          color="warning"
          onClick={() =>
            deleteAndRedirect(
              id,
              `${REACT_APP_BASE_API_URL}/${endpoint}`,
              "pinstatus",
              true,
              navigate,
              redirectUrl
            )
          }
        >
          Delete This Status
        </Button>
      </Grid>
    </Grid>
  );
}
