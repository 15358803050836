import React from "react";
import { ReactComponent as Logo } from "./svgs/biller-icon.svg";
import { SvgIcon } from "@mui/material";
const Biller = () => (
  <SvgIcon>
    <Logo />
  </SvgIcon>
);

export default Biller;
